/* General container styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  animation: fadeIn 0.5s ease-in-out;
}

/* Company Logo */
.logo {
  width: 150px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.1); /* Slight scale-up on hover */
}

/* Title of the app */
.title {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 600;
  color: #333;
}

/* Form container */
.form {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.form:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

/* Input container */
.inputContainer {
  margin-bottom: 20px;
}

/* Label for inputs */
.label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
}

/* Input fields */
.input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.input:focus {
  border-color: #007bff;
  outline: none;
}

/* Row for first name and last name */
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row .inputContainer {
  flex: 0 0 48%;
}

@media (max-width: 600px) {
  .row .inputContainer {
    flex: 0 0 100%;
  }
}

/* Checkbox container */
.checkboxContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 10px;
}

/* Submit button */
.submitButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submitButton:hover {
  background-color: #0056b3;
  transform: scale(1.02);
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Spinner styles */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Error and success messages */
.errorMessage {
  color: #e74c3c;
  margin-bottom: 15px;
  font-weight: 500;
}

.successMessage {
  color: #28a745;
  margin-bottom: 15px;
  font-weight: 500;
}

/* Password field styles */
.passwordContainer {
  display: flex;
  align-items: center;
}

.toggleButton {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggleButton:hover {
  background-color: #0056b3;
}

/* Modal overlay that covers the entire background */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal container */
.modal {
  background-color: #fff;
  padding: 30px;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: slideDown 0.4s ease;
}

/* Close button (top-right corner) */
.close {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.close:hover {
  color: #e74c3c;
  transform: rotate(90deg) scale(1.2);
}

/* Modal content */
.modalContent {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.6;
}

.modalContent .fa-check-circle {
  color: #28a745;
  font-size: 48px;
  margin-bottom: 10px;
}

.modalContent .fa-times-circle {
  color: #dc3545;
  font-size: 48px;
  margin-bottom: 10px;
}

/* Back button inside the modal */
.backButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-right: 10px;
}

.backButton:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Send Email button */
.sendEmailButton {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.sendEmailButton:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.sendEmailButton:active {
  transform: scale(0.98);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive modal on small devices */
@media (max-width: 768px) {
  .modal {
    width: 90%;
    padding: 20px;
  }

  .close {
    font-size: 20px;
    top: 10px;
    right: 15px;
  }
}

/* Responsive form */
@media (max-width: 600px) {
  .form {
    padding: 20px;
  }

  .row .inputContainer {
    flex: 0 0 100%;
    margin-bottom: 15px;
  }

  .input {
    padding: 8px;
  }
}
