/* src/stats/AlarmsCountCard.module.css */

/* Container for the AlarmsCountCard component */
.AlarmsCountCardContainer {
    width: 350px;
    margin-right: 10px;
    margin-bottom: 15px;
    display: inline-block;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .AlarmsCountCardContainer {
      width: 100%;
      margin-right: 0;
      display: block;
    }
  }
  