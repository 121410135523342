:root {
  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --background-color: #f0f0f0;
  --tree-node-bg: #f8f8f8;
  --tree-node-hover-bg: #e6f7ff;
  --table-header-bg: #f1f1f1;
  --table-row-hover-bg: #f7f7f7;
  --border-color: #ddd;
  --border-radius: 8px;
  --transition-speed: 0.3s;
  --table-header-gradient-start: #f1f1f1;
  --table-header-gradient-end: #e8e8ec;
  --table-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  --cell-padding: 12px;
  --font-family-base: 'Arial', sans-serif;
}

.tableContainer {
  width: 100%;
  margin: auto;
  padding: 1rem;
}

.filterContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.tableWrapper {
  overflow: auto;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--table-shadow);
}

.styledTable {
  width: 100%;
  border-collapse: collapse;
  font-family: var(--font-family-base);
  font-size: 0.9rem;
}

.styledTable thead {
  position: sticky;
  top: 0;
  background: linear-gradient(
    to bottom,
    var(--table-header-gradient-start),
    var(--table-header-gradient-end)
  );
  z-index: 10;
}

.tableHeader {
  padding: var(--cell-padding);
  text-align: left;
  border-bottom: 2px solid var(--border-color);
  color: #333;
  font-weight: bold;
  transition: background-color var(--transition-speed) ease-in-out;
}

.tableHeader:hover {
  background-color: var(--background-color);
}

.tableRow {
  cursor: grab;
  transition: background-color var(--transition-speed) ease-in-out,
    transform 0.2s ease-in-out;
}

.tableRow:nth-child(even) {
  background-color: var(--tree-node-bg);
}

.tableRow:hover:not(.nonDraggableRow) {
  background-color: var(--tree-node-hover-bg);
  transform: scale(1.01);
}

.nonDraggableRow {
  cursor: default;
  background-color: var(--tree-node-bg);
  transition: background-color var(--transition-speed) ease-in-out;
}

.nonDraggableRow:hover {
  background-color: var(--background-color);
  transform: none; /* No scale effect for non-draggable rows */
}

.draggingRow {
  cursor: grabbing;
  background-color: rgba(0, 128, 255, 0.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.tableCell {
  padding: var(--cell-padding);
  border-bottom: 1px solid var(--border-color);
  text-align: left;
  color: #555;
}

.hiddenColumn {
  display: none;
}

.noData {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #888;
}

.noDataContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.noDataContent span {
  font-size: 2em;
}

/* dragImage component  */

.dragImage {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}