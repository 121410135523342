/* Wrapper around the DatePicker to align with the HeadRow styles */
.datePickerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }
  
  /* DatePicker input, inheriting font styles from AlarmsList */
  .datePickerInput {
    width: 100%;
    max-width: 250px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em; /* Matches Label font size in AlarmsList */
    color: #333;
  }
  
  /* Styling the dropdown calendar */
  .rs-picker-menu {
    background-color: #ffffff; /* Matches AlarmsListContainer background */
    border: 1px solid #e0e0e0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); /* Slightly elevated shadow */
    border-radius: 8px; /* Matches AlarmsListContainer border radius */
  }
  
  /* Highlight selected date */
  .rs-picker-toggle-active {
    background-color: #004aac; /* Matches TitleSeparator color */
    color: #fff;
  }
  
  /* Hover effect for the DatePicker */
  .datePickerInput:hover {
    border-color: #004aac;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .datePickerWrapper {
      width: 100%;
      justify-content: flex-start;
    }
  }
  