@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    background-color: #F2F7FB!important;
} 

input[type='button'], input[type='submit'], input[type='reset'], button {
    border-width: 0;
}

svg[id^='raphael-paper'] > g:nth-of-type(3){
    display: none;
}

.rs-picker-menu {
    z-index: 1000!important;
}
/*src/globalcss*/

/* src/App.module.css */

:root {
  --menu-expanded-width: 240px; /* Adjust as needed */
  --menu-collapsed-width: 60px;
  --header-height: 60px; /* Use the imported header height */
}

.App_App__16ZpL {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App_AppContent__3mllT {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}

.App_MainContent__Zn9hG {
  flex: 1 1;
  position: relative;
  overflow-y: auto;
  transition: margin-left 0.3s ease;
  margin-top: var(--header-height);
  height: calc(100vh - var(--header-height));
}

.App_MainContentExpanded__3Tllm {
  margin-left: var(--menu-expanded-width);
}

.App_MainContentCollapsed__1497g {
  margin-left: var(--menu-collapsed-width);
}

/*src/globalcss*/
/* -------------- */
/* --- GLOBAL --- */
/* -------------- */


/* -------------- */
/* --- HEADER --- */
/* -------------- */


/* ---------- */
/* -- MENU -- */
/* ---------- */

/* ---------- */
/* -- TABS -- */
/* ---------- */

/* src/navigation/header/Header.module.css */

.Header_HeaderContainer__AZE8Q {
  width: 100%;
  height: 60px;
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 180;
  border-bottom: 1px solid rgb(217, 217, 217);
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjusted to space between logo and profile */
  padding: 0 20px; /* Padding on both sides */
}

.Header_HeaderLogo__3re23 {
  height: calc(60px * 0.6); /* Adjust logo size */
  cursor: pointer;
}

.Header_HeaderProfileContainer__25hpT {
  position: relative; /* Changed from fixed to relative */
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Header_HeaderProfileContent__30jpK {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.Header_HeaderProfileContent__30jpK:hover {
  background-color: rgba(3, 155, 217, 0.11);
}

.Header_HeaderProfileContent__30jpK label {
  margin: 0 8px;
  font-size: 14px;
}

.Header_ProfileIcon__2-6Q8 {
  font-size: 1.2rem;
}

.Header_ChevronIcon__1V05b {
  font-size: 0.8rem;
}

.Header_HeaderLogoutContainer__3g8rT {
  position: absolute;
  top: calc(60px);
  right: 0;
  background-color: #ffffff;
  border: 1px solid #bebebe;
  width: 160px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 190;
}

.Header_HeaderLogoutContainer__3g8rT::before {
  content: "";
  position: absolute;
  top: -8px;
  right: 16px;
  border-width: 0 8px 8px 8px;
  border-style: solid;
  border-color: transparent transparent #bebebe transparent;
}

.Header_HeaderLogoutContent__1SHMe {
  padding: 10px;
  text-align: center;
}

.Header_HeaderLogoutContent__1SHMe label {
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.Header_HeaderLogoutContent__1SHMe label:hover {
  color: #000;
}

@media (max-width: 768px) {
  .Header_HeaderContainer__AZE8Q {
    padding: 0 10px;
  }

  .Header_HeaderProfileContent__30jpK label {
    display: none; /* Hide username on smaller screens */
  }
}

/*src/globalcss*/

/*noinspection CssInvalidAtRule*/
/*@custom-media --large (min-width: 960px);*/

/* Menu.module.css */

.Menu_MenuContainer__2mRIJ {
  position: fixed;
  z-index: 200;
  width: var(--menu-expanded-width);  
  height: 100%;
  background-color: var(--v-menu-color);
  left: 0;
  overflow: hidden; /* Prevent scrollbars during transition */
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease, box-shadow 0.3s ease;
  flex-shrink: 0;

}

.Menu_MenuContainerCollapsed__2549L {
  width: var(--menu-collapsed-width);
  overflow: hidden;
}

.Menu_CollapseButton__uU3Jm {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.Menu_CollapseIcon__2vehL {
  font-size: 1.2rem;
  color: var(--v-menu-item-text-color);
  transition: transform 0.3s ease;
}

.Menu_RotateIcon__2XJhx {
  transform: rotate(180deg); /* Rotate icon when menu is collapsed */
}

.Menu_MenuHeader__3Boc4 {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Menu_HeaderLogo__1gChD {
  height: calc(60px * 0.7);
  margin-top: calc(60px * 0.15);
  margin-bottom: calc(60px * 0.15);
  align-self: center;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.Menu_HeaderLogoCollapsed__3J8jr {
  opacity: 0;
  transform: scale(0.8); /* Scale down when collapsed */
}

.Menu_MenuHeaderSeparator__SlAMK {
  width: 100%;
  height: 1px;
  background-color: rgb(217, 217, 217);
  margin: 0 auto 10px auto;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.Menu_MenuHeaderSeparatorCollapsed__kXNER {
  opacity: 0; /* Hide separator when collapsed */
}

.Menu_TreeContainer__2M4CG {
  flex: 1 1;
  overflow-y: auto; /* Vertical scroll only */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%;
  height: 100%;
}

.Menu_MenuAppVersion__10YnV {
  padding: 15px 0;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.Menu_MenuAppVersionCollapsed__NmmlF {
  opacity: 0;
  pointer-events: none; /* Hide text and disable interactions when collapsed */
}

@media (max-width: 599px) {
  .Menu_MenuContainer__2mRIJ {
    display: none; /* Hide menu on small screens */
  }
}

/* HeaderNode.module.css */

:root {
    --v-menu-color: #ffffff;
    --v-menu-item-text-color: #333333;
    --v-header-height: 60px;
  }
  
  .HeaderNode_HeaderContainer__144_s {
    width: 100%;
    position: relative;
    background-color: var(--v-menu-color);
  }
  
  .HeaderNode_MenuHeader__2h_nu {
    height: var(--v-header-height);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .HeaderNode_HeaderLogo__3qBeh {
    height: calc(var(--v-header-height) * 0.7);
    margin-top: calc(var(--v-header-height) * 0.15);
    margin-bottom: calc(var(--v-header-height) * 0.15);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .HeaderNode_CollapseButton__2mF4- {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .HeaderNode_CollapseButtonCollapsed__2gf9_ {
    position: static;
  }
  
  .HeaderNode_CollapseButton__2mF4-:not(.HeaderNode_CollapseButtonCollapsed__2gf9_) {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .HeaderNode_CollapseIcon__3zE0I {
    font-size: 1.2rem;
    color: var(--v-menu-item-text-color);
    transition: transform 0.3s ease;
  }
  
  .HeaderNode_RotateIcon__3Jq_b {
    transform: rotate(180deg);
  }
  
  .HeaderNode_CollapseButton__2mF4-:hover .HeaderNode_CollapseIcon__3zE0I {
    color: #007bff; /* Change color on hover */
  }
  
/* FooterNode.module.css */

.FooterNode_FooterContainer__3vOwS {
    width: 100%;
    text-align: center;
    padding: 15px 0;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease;
  }
  
  .FooterNode_MenuAppVersion__nbl3l {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .FooterNode_MenuAppVersionCollapsed__3z9zQ {
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
  }
  
  .FooterNode_MenuCopyright__2Pnqg {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.5);
  }
  
/* MenuItemContent.module.css */

:root {
  --v-menu-selected-background-color: #00b7ff;
  --v-menu-selected-text-color: #ffffff;
  --v-menu-unselected-text-color: #333333;
  --v-menu-hover-background: #f0f0f0;
  --v-menu-hover-text-color: #000000;
}

.MenuItemContent_MenuItemContainer__1jH0a {
  width: 90%;
  max-width: 90%;
  overflow: hidden;
  font-weight: bold;
  color: var(--v-menu-unselected-text-color);
  margin: 4px 0;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, margin 0.3s ease, padding 0.3s ease;
}

.MenuItemContent_MenuItemContainer__1jH0a:hover {
  background-color: var(--v-menu-hover-background);
  color: var(--v-menu-hover-text-color);
}

.MenuItemContent_MenuItemActive__1DKm3 {
  background-color: var(--v-menu-selected-background-color);
  color: var(--v-menu-selected-text-color);
}

.MenuItemContent_MenuItemHeader__1RZIC {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  justify-content: flex-start;
  transition: padding 0.3s ease;
  width: 90%;
  max-width: 90%;
}

.MenuItemContent_MenuItemToggleIcon__2N1o- {
  margin-right: 12px;
  font-size: 1rem;
  cursor: pointer;
}

.MenuItemContent_MenuItemIcon__kD4wr {
  margin-right: 8px;
  font-size: 1.2rem;
}

.MenuItemContent_MenuItemText__2Udf4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: inherit;
    flex-grow: 1;
}

.MenuItemContent_Collapsed__2frXV .MenuItemContent_MenuItemContainer__1jH0a {
  margin: 2px 0;
}

.MenuItemContent_Collapsed__2frXV .MenuItemContent_MenuItemHeader__1RZIC {
  padding: 6px;
  justify-content: center;
}

.MenuItemContent_Collapsed__2frXV .MenuItemContent_MenuItemToggleIcon__2N1o- {
  display: none;
}

.MenuItemContent_Collapsed__2frXV .MenuItemContent_MenuItemText__2Udf4 {
  display: none;
}

.MenuItemContent_Collapsed__2frXV .MenuItemContent_MenuItemIcon__kD4wr {
  margin-right: 0;
  font-size: 1.4rem;
}

/* Level-specific Indentation */
.MenuItemContent_MenuItemLevel0__Dya03 {
  margin-left: 0;
}

.MenuItemContent_MenuItemLevel1__cZH_C {
  margin-left: 20px;
}

.MenuItemContent_MenuItemLevel2__1gWxt {
  margin-left: 40px;
}

.MenuItemContent_MenuItemLevel3__3QAKe {
  margin-left: 60px;
}

/* Remove indentation when collapsed */
.MenuItemContent_Collapsed__2frXV .MenuItemContent_MenuItemLevel1__cZH_C,
.MenuItemContent_Collapsed__2frXV .MenuItemContent_MenuItemLevel2__1gWxt,
.MenuItemContent_Collapsed__2frXV .MenuItemContent_MenuItemLevel3__3QAKe {
  margin-left: 0;
}

.Mediaspots_MediaspotsContainer__2_j9w {
    padding: 15px;
    position: relative;
    left: 0px;
    width: calc(100% - 0px);
}

.Mediaspots_MediaspotsTreeContainer__3xS-i {
    position: fixed;
    width: 0px;
    height: calc(100% - 60px);
    left: 220px;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: scroll;
    box-shadow: 1px 0 3px 0 rgb(0 0 0 / 32%);
    z-index: 160;
}



.Tree_NodeIcon__134Im {
    font-size: 12px;
    margin-right: 5px;
    z-index: 50;
}

.Tree_TreeLoadingContainer__1KPPc {
    text-align: center;
}

.Tree_TreeNode__1auVp {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 3px;

    position: relative;
}

.Tree_TreeNode__1auVp:hover {
    background: rgba(176, 213, 236, 0.19);
}

.Tree_NodeStatusIcon__1XWxF {
    width: 18px;
    height: 18px;
    display: inline;
    margin-right: 5px;
}

.Tree_tooltip__2N_yu {
    z-index: 1500!important;
}

.Tree_NodeIconOK__X-a7L {
    filter: invert(57%) sepia(60%) saturate(2477%) hue-rotate(85deg) brightness(116%) contrast(60%); /* green */
}

.Tree_NodeIconWarning__2agRR {
    filter: invert(64%) sepia(88%) saturate(2551%) hue-rotate(0deg) brightness(102%) contrast(103%); /* orange */
}

.Tree_NodeIconAlert__1Wcg9 {
    filter: invert(75%) sepia(51%) saturate(2500%) hue-rotate(310deg) brightness(100%) contrast(90%); /* red */
}

.Tree_NodeIconAddButton__23FCJ, .Tree_NodeIconEditButton__3Scsa, .Tree_NodeIconDissociateButton__2S3x2, .Tree_NodeIconDeleteButton__3_Q-R {
    padding: 0 5px 0px;
    color: white;
    border-radius: 5px;
    margin-left: 3px;
}

.Tree_NodeIconAddButton__23FCJ {
    background-color: #0095ff;
}

.Tree_NodeIconEditButton__3Scsa {
    background-color: #ff8c00;
}

.Tree_NodeIconDissociateButton__2S3x2, .Tree_NodeIconDeleteButton__3_Q-R {
    background-color: #fd5858;
}


.Tree_NodeIconEditButtonPicto__13pJq, .Tree_NodeIconDissociateButtonPicto__GB7_X, .Tree_NodeIconDeleteButtonPicto__2F3D4 {
    padding: 2px;
}

.NewArea_NewAreaInput__KL5Uo {
    border: none;
    background: none;
    border-bottom: 1px solid #BEBEBE;
}

.NewArea_NewAreaLoader__21IPz {
    display: inline;
    position: absolute;
    margin-top: 4px;
    margin-left: 4px;
}

.NewArea_NewAreaReserveCheckbox__Zc_oz {
    margin-left: 10px;
}

.DroppableArea_DroppableAreaOverlay__35hna {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin: -5px;
}

.DissociationModalContainer_PopupOverlay__RlVSp{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000075;
    z-index: 200;
    top: 0;
    left: 0;
}



.DissociationModalContainer_DissociationModalPopupContainer__3O5mj {
    z-index: 210;
    cursor: auto;
    padding: 10px;
    background-color: white;
    border-radius: 15px;
    margin: auto;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);

    max-height: 80vh;
    overflow-y: scroll;
}

.DissociationModalContainer_DissociationModalPopupContent__3y1Kw > * {
    margin-top: 5px;
    margin-bottom: 5px;
}

.DissociationModalContainer_DissociationModalPopupContent__3y1Kw {
    padding: 10px;
    text-align: center;
}

.DissociationModalContainer_DissociationModalPopupTitle__3vPMN {
    font-size: 23px;
    text-align: center;
    display: block;
}

.DissociationModalContainer_DissociationModalSpinner__2Wt_F {
    margin-top: 15px;
}

.DissociationModalContainer_DissociationModalValidateButton__16PzH:disabled {
    background: #868686;
}


.DissociationModalContainer_DissociationModalValidateButton__16PzH {
    background: #e04848;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding: 12px 25px;
    border-radius: 12px;
    font-size: 16px;
    margin-top: 15px;
}

.DissociationModalContainer_DissociationModalStoreNodeSelect__37Hlu {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid black;
    border-radius: 5px;
}

.DevicesMapping_AdminAreasContainer__q6lz8 {
    position: absolute;
    /* width: calc(100% - v-menu-width); */
    width: 100%;
    height: calc(100% - 60px);
    padding: 15px;

}

.DevicesMapping_AdminAreasColumns__vcSEj {
    display: flex;
    height: 100%;
}

.DevicesMapping_AdminAreasTreeContainer__3u_tY {
    width: 35%;
}

.DevicesMapping_AdminDevicesTreeContainer__2eR0e {
    width: 65%;
}

.DevicesMapping_AdminAreasTreeContainer__3u_tY, .DevicesMapping_AdminDevicesTreeContainer__2eR0e {
    background: white;
    padding: 10px;
    box-shadow: 2px 4px 5px #cacaca;
    border-radius: 8px;
    margin: 5px;
}

.DevicesMapping_AdminAreasHeaderLabel__1q6Tf {
    width: calc(100% + 20px);
    height: 50px;
    font-size: 20px;
    color: white;
    display: inline;
}


.DevicesMapping_AdminAreasTreeContainer__3u_tY > .DevicesMapping_AdminAreasColumnsHeader__14_4w {
    background-color: #FBB53C;
}

.DevicesMapping_AdminDevicesTreeContainer__2eR0e > .DevicesMapping_AdminAreasColumnsHeader__14_4w {
    background-color: #0095ff;
}


.DevicesMapping_AdminAreasSentenceDescription__1quiR {
    font-size: 18px;
    margin-bottom: 10px;
    display: block;
}

.DevicesMapping_AdminAreasTreeContent__1-nTl {
    overflow: scroll;
    height: calc(100% - 50px);
}

.DevicesMapping_AdminAreasColumnsHeader__14_4w {
    position: relative;

    margin: -10px;
    margin-bottom: 10px;
    padding: 10px;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.DevicesMapping_AdminAreasHideVisibleNodeButton__37yW3 {
    border: 1px solid white;
    position: absolute;
    right: 10px;
    background-color: transparent;
    color: white;
    font-weight: bold;
    border-radius: 7px;
    padding: 4px;
}


.DevicesMapping_PopupOverlay__106Lp{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000075;
    z-index: 200;
    top: 0;
    left: 0;
}


.DevicesMapping_DeviceEditionModalPopupContainer__1KtoD {
    z-index: 210;
    cursor: auto;
    padding: 10px;
    background-color: white;
    border-radius: 15px;
    margin: auto;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);

    max-height: 80vh;
    overflow-y: scroll;
}

.DevicesMapping_DeviceEditionModalPopupContent__1O--k > * {
    margin-top: 5px;
    margin-bottom: 5px;
}

.DevicesMapping_DeviceEditionModalPopupContent__1O--k {
    padding: 10px;
    text-align: center;
}
.DevicesSearch_DeviceSearchContainer__37Kks {
    /*height: 100%;*/
    overflow: scroll;
    height: calc(100% - 50px);
}

.DevicesSearch_DeviceSearchInputContainer__12In6 > div {
    display: inline;
    margin-left: 10px;
    margin-right: 5px;
}

.DevicesSearch_DeviceSearchInputContainer__12In6{
    margin-top: 5px;
    margin-bottom: 5px;
}

.DevicesSearch_DeviceSearchResultsSeparator__2sgW_ {
    height: 1px;
    width: 100%;
    background: #d0d0d0;
    margin-top: 20px;
    margin-bottom: 20px;
}


.DevicesSearch_DeviceSearchResultsContainer__3vkUO, .DevicesSearch_DeviceSearchResultsContainer__3vkUO > table {
    width: 100%;
}

.DevicesSearch_DeviceSearchResultsContainer__3vkUO > table  {
    margin-top: 10px;
}


.DevicesSearch_DeviceSearchResultsContainer__3vkUO > table > thead > tr > td {
    border-bottom: 1px solid #d7d7d7;
}

.DevicesSearch_DeviceSearchResultsContainer__3vkUO > table > tbody > tr > td {
    border-bottom: 1px solid #d7d7d7;
}

.DevicesSearch_DeviceSearchResultsContainer__3vkUO > table > thead > tr > td {
    text-align: center;
}

.DevicesSearch_DeviceSearchResultsContainer__3vkUO > table > tbody > tr > td {
    border-right: 1px solid #d7d7d7;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
}

.DevicesSearch_DeviceSearchResultsContainer__3vkUO > table > tbody > tr > td:last-of-type {
    border-right: none;
}

.DevicesSearch_DeviceSearchResultRowUndraggable__2hj0z {
    background-color: rgba(208, 207, 207, 0.15);
    cursor: default;
}

.DevicesSearch_DeviceSearchResultRowDraggable__7tHS7 {
    cursor: grab;
}

.DevicesSearch_DeviceSearchResultsContainer__3vkUO > table > tbody > tr:hover:not(.DevicesSearch_DeviceSearchResultRowUndraggable__2hj0z) {
    background-color: #fbb53c61;
}


.DevicesSearch_DeviceSearchResultsContainer__3vkUO > table > tbody > tr > td:first-of-type {
    padding: 10px;
}

.DevicesSearch_DeviceSearchResultsContainer__3vkUO > table > tbody > tr > td:nth-of-type(2) {
    text-transform: capitalize;
    white-space:nowrap;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}


.DevicesSearch_DeviceSearchResultsContainer__3vkUO > table {
    table-layout: auto;
    border-collapse: collapse;
    width: 100%;
}

.DevicesSearch_AbsorbingColumn__PeHOb {
    width: 100%;
}

.DevicesSearch_DeviceSearchValidateButton__3_93y {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 7px;
    background-color: #0e7ab9bd;
    color: white;
    box-shadow: 3px 1px 5px #bebebe;
}

.DevicesSearch_DeviceSearchInputContainer__12In6 > input {
    background: none;
    border: none;
    border-bottom: 1px solid #bebebe;
}

.Provisionning_ProvisionningContainer__3qcpw {
    padding: 20px!important;
}

.Provisionning_ProvisionningContainer__3qcpw > form > div > div {
    padding: 8px;
}

.Provisionning_ProvisionningContainer__3qcpw > form > div > div > label{
    display: block;
}

.Provisionning_ProvisionningContainer__3qcpw > form > div > div > input{
    width: 400px;
}

.Provisionning_ProvisionningContainer__3qcpw > h4 {
    margin-bottom: 15px;
}


.Provisionning_ProvisionningFieldsTable__2G2pZ > tr > td {
    text-align: left;
}

.Provisionning_ProvisionningFieldsTable__2G2pZ > tr > td > input, .Provisionning_ProvisionningFieldsTable__2G2pZ > tr > td > select{
    width: 350px;
    margin: 8px;
}

.Provisionning_ProvisionningValidateButton__2Hkvq, .Provisionning_ProvisionningEditButton__3B6dP {
    height: 40px;
    margin-top: 12px;
    border-radius: 8px;
    color: white;
}

.Provisionning_ProvisionningValidateButton__2Hkvq {
    background-color: #69b851;
}

.Provisionning_ProvisionningEditButton__3B6dP {
    background-color: darkorange;
}
/* Root Variables for Dynamic Widths */
:root {
  --tab-padding: 12px 20px;
  --tab-font-size: 16px;
  --tab-icon-size: 1.1rem;
}

/* Container for Each Tab Item */
.TabNavigation_tabItem__O-mb8 {
  display: inline-flex;
  align-items: center;
  padding: var(--tab-padding);
  font-size: var(--tab-font-size);
  color: #555;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
}
/* Add this to your CSS */
.TabNavigation_tabItem__O-mb8 label {
  cursor: pointer;
}

/* Selected Tab Styling */
.TabNavigation_selectedTab__2sctP {
  color: var(--v-primary);
  font-weight: bold;
  border-bottom-color: var(--v-primary);
}

/* Default (Unselected) Tab Styling */
.TabNavigation_tab__3Y-Xl {
  color: var(--v-secondary);
}

/* Indicator Line for Selected Tab */
.TabNavigation_tabSelectedLine__AOAir {
  width: 100%;
  height: 2px;
  background-color: var(--v-primary);
  transition: transform 0.3s ease;
  cursor: pointer;
}

/* Responsive Icon Styling */
.TabNavigation_TabIcon__uUOte {
  font-size: var(--tab-icon-size);
  margin-right: 8px;
  cursor: pointer;
}

/* Hover State */
.TabNavigation_tabItem__O-mb8:hover {
  color: var(--v-primary);
  cursor: pointer;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .TabNavigation_tabItem__O-mb8 {
    padding: 10px 16px;
    font-size: 14px;
  }

  .TabNavigation_TabIcon__uUOte {
    font-size: 1rem;
    margin-right: 6px;
  }
}

@media (max-width: 480px) {
  .TabNavigation_tabItem__O-mb8 {
    padding: 8px 12px;
    font-size: 13px;
  }

  .TabNavigation_TabIcon__uUOte {
    font-size: 0.9rem;
  }
}

/* Container for DateRangeSelector with responsive adjustments */
.DateRangeSelector_DateRangeContainer__3f6os {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin: 10px 0;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--v-border-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.DateRangeSelector_DateRangeContainer__3f6os:hover {
  background-color: var(--v-secondary);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Styling the DateRangePicker input field */
.DateRangeSelector_DateRangePicker__cGFFa {
  width: 100%;
  max-width: 320px;
  border-radius: 4px;
  border: 1px solid var(--v-border-color);
  padding: 8px 12px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.DateRangeSelector_DateRangePicker__cGFFa:focus,
.DateRangeSelector_DateRangePicker__cGFFa:hover {
  border-color: var(--v-primary);
  background-color: #fff;
}

.DateRangeSelector_DateRangePicker__cGFFa::placeholder {
  color: #aaa;
  font-style: italic;
}

/* DateRangePicker's Dropdown Calendar Styling */
.DateRangeSelector_DateRangeDropdown__1LAoX {
  border: 1px solid var(--v-border-color);
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  width: 100%;
  max-width: 320px;
}

.DateRangeSelector_DateRangeDropdown__1LAoX .DateRangeSelector_rs-picker-toggle__1LM-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  font-size: 16px;
  color: #555;
  background-color: white;
  cursor: pointer;
}

.DateRangeSelector_DateRangeDropdown__1LAoX .DateRangeSelector_rs-picker-toggle__1LM-2:hover {
  background-color: var(--v-secondary);
}

/* Date Range Options and Values */
.DateRangeSelector_RangeOption__2TWjr {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.DateRangeSelector_RangeOption__2TWjr:hover {
  background-color: var(--v-primary);
  color: white;
}

/* Responsiveness for Smaller Screens */
@media (max-width: 768px) {
  .DateRangeSelector_DateRangeContainer__3f6os {
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
  }

  .DateRangeSelector_DateRangePicker__cGFFa {
    width: 100%;
  }

  .DateRangeSelector_DateRangeDropdown__1LAoX {
    width: 100%;
  }
}

/* Specific Styles for Very Small Screens */
@media (max-width: 480px) {
  .DateRangeSelector_DateRangeContainer__3f6os {
    padding: 6px;
  }

  .DateRangeSelector_DateRangePicker__cGFFa {
    font-size: 14px;
    padding: 6px 10px;
  }

  .DateRangeSelector_RangeOption__2TWjr {
    font-size: 13px;
  }
}

:root {
  --menu-expanded-width: 240px;
  --menu-collapsed-width: 60px;
  --header-height: 53px;
}

.Tabs_tabContainer__2XVzC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: var(--header-height);
  left: var(--menu-expanded-width);
  width: calc(100% - var(--menu-expanded-width));
  height: 53px;
  z-index: 110;
  background-color: white;
  transition: left 0.3s ease, width 0.3s ease;
  padding: 0 40px;
}

.Tabs_tabContainerCollapsed__1Vd47 {
  left: var(--menu-collapsed-width);
  width: calc(100% - var(--menu-collapsed-width));
}

@media (max-width: 768px) {
  .Tabs_tabContainer__2XVzC {
    width: 100%;
    left: 0;
  }
}

.Tabs_tabContent__nhaK8 {
  display: flex;
  flex: 1 1;
  overflow-x: auto;
  white-space: nowrap;
}

.Tabs_filterIconContainer__3wQGV {
  position: relative;
  display: inline-block;
}

.Tabs_FilterIcon__2KIPJ {
  display: inline-block;
  cursor: pointer;
}


.Tabs_selectedTab__3GQld {
  color: #004aac;
  font-weight: bold;
}

.Tabs_tabSelectedLine__3g_MH {
  background-color: #004aac;
  height: 2px;
  margin-top: 15px;
}

/* src/navigation/tabs/filter/FilterModal.module.css */

:root {
  /* Assign imported constants to CSS variables */
  --primary-color: #004aac;
  --secondary-color: #68686A;
  --border-color: undefined;
  --light-gray: undefined;

  /* Modal variables */
  --modal-width: 340px;
  --modal-padding: 24px;
  --modal-bg-color: #ffffff;
  --modal-border-radius: 12px;
  --modal-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  --close-button-size: 26px;
  --close-button-color: #aaa;
  --hover-bg-color: #f1f1f1;
  --apply-button-hover: #003d73;
  --modal-title-font-size: 1.3rem;
}

/* Main Filter Modal Container */
.FilterModal_FilterWindow__2tKzN {
  position: absolute;
  top: 100%; /* Positions the dropdown directly below the icon */
  left: 0;
  width: var(--modal-width);
  background-color: var(--modal-bg-color);
  border-radius: var(--modal-border-radius);
  padding: var(--modal-padding);
  box-shadow: var(--modal-shadow);
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}

/* Close Button */
.FilterModal_ModalClose__3ti33 {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: var(--close-button-size);
  color: var(--close-button-color);
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.FilterModal_ModalClose__3ti33:hover {
  color: var(--primary-color);
}

/* Modal Title */
.FilterModal_ModalTitle__2uDDh {
  font-size: var(--modal-title-font-size);
  color: var(--primary-color);
  margin-bottom: 16px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 12px;
}

/* Modal Content */
.FilterModal_ModalContent__2Vz95 {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
  max-height: 400px;
  overflow-y: auto;
  padding-top: 8px;
}

/* Checkbox Option Container */
.FilterModal_ModalOption__2HVI- {
  display: flex;
  align-items: center;
  padding: 8px 0;
  transition: background-color 0.2s ease-in-out;
  border-radius: 6px;
}

.FilterModal_ModalOption__2HVI-:hover {
  background-color: var(--hover-bg-color);
}

.FilterModal_ModalOption__2HVI- input[type="checkbox"] {
  margin-right: 12px;
  cursor: pointer;
  transform: scale(1.1);
}

.FilterModal_ModalTitleOption__3NrXx {
  color: var(--secondary-color);
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.2s ease-in-out;
}

.FilterModal_ModalTitleOption__3NrXx:hover {
  color: var(--primary-color);
}

/* Select All Checkbox */
.FilterModal_ModalOption__2HVI- #FilterModal_selectAll__3zEI6 {
  font-weight: bold;
}

/* Apply Button */
.FilterModal_ModalApply__3Of0g {
  margin-top: 20px;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease;
  width: 100%;
}

.FilterModal_ModalApply__3Of0g:hover {
  background-color: var(--apply-button-hover);
  transform: scale(1.02);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .FilterModal_FilterWindow__2tKzN {
    width: 90vw;
    left: calc(50% - 45vw);
  }

  .FilterModal_ModalTitle__2uDDh {
    font-size: 1.1rem;
  }

  .FilterModal_ModalOption__2HVI- {
    padding: 6px 0;
  }
}

/*src/card/Card.module.css*/

.Card_Card__3Q0xW {
    padding: 10px;
    border-radius: 10px;

    height: 100%;
}

.Card_Card__3Q0xW > label:first-of-type{
    color: white;
    font-size: 20px;
    font-weight: bold;
    display: block;
}

.Card_Card__3Q0xW > label:nth-child(2n){
    color: white;
    width: 100%;
    text-align: center;
    font-size: 25px;
    margin-top: 10px;
}

.Card_CardDetailsButton__3j1L2 {
    background: none;
    border: none;
    position: absolute;
    bottom: 5px;
    right: 15px;
    color: white;
    padding: 5px;
}

.Card_Card__3Q0xW > label > a:hover {
    color: white;
    text-decoration: none;
}

/* src/stats/AlarmsCountCard.module.css */

/* Container for the AlarmsCountCard component */
.AlarmsCountCard_AlarmsCountCardContainer__3BiOT {
    width: 350px;
    margin-right: 10px;
    margin-bottom: 15px;
    display: inline-block;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .AlarmsCountCard_AlarmsCountCardContainer__3BiOT {
      width: 100%;
      margin-right: 0;
      display: block;
    }
  }
  
/* src/stats/AlarmsList.module.css */

/* Container for the entire AlarmsList component */
.AlarmsList_AlarmsListContainer__2nxrW {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Header row containing the label and date picker */
  .AlarmsList_HeadRow__1aBYV {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  /* Style for the table header cells */
.AlarmsList_HeaderCell__2BA89 {
  position: relative; /* Necessary for the absolute positioning of the filter button */
}
  /* Label styling */
  .AlarmsList_Label__mCDsT {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
  }
  
  /* Separator under the title */
  .AlarmsList_TableHeader__GX__j {
    font-weight: bold;
    background-color: #f2f2f2; /* Light gray background for the header */
    padding: 10px;
    text-align: left;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .AlarmsList_SortIcon__29T8p {
    margin-left: 5px; /* Spacing between header text and sort icon */
    color: #6c757d; /* Neutral color for the icon */
  }
  
  .AlarmsList_TitleSeparator__127SN {
    height: 2px;
    background-color: #004aac;
    width: 100%;
    margin-bottom: 15px;
  }
  
  /* Table container with scroll */
  .AlarmsList_TableContainer__2Hh97 {
    overflow-y: auto;
    max-height: 400px; /* Adjust as needed */
  }
  
  /* Styling for the table */
  .AlarmsList_Table__y2XoO {
    width: 100%;
    border-collapse: collapse;
  }
  
  .AlarmsList_Table__y2XoO th,
  .AlarmsList_Table__y2XoO td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    color: #333;
  }
  
  .AlarmsList_Table__y2XoO th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  
  .AlarmsList_Table__y2XoO tr:hover {
    background-color: #f9f9f9;
  }
  
  /* Styling for recent event rows */
  .AlarmsList_RecentEventRow__38dXQ {
    background-color: #e6f7ff;
  }
  
  /* No Alarms Label */
  .AlarmsList_NoAlarmsLabel__3a5FE {
    padding: 20px;
    text-align: center;
    color: #666;
  }
  
  /* Download Button Container */
  .AlarmsList_DownloadButtonContainer__3XhOm {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  
  /* Download Button */
  .AlarmsList_DownloadButton__Kd49J {
    padding: 8px 16px;
    border-radius: 5px;
    background: #004aac;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
  }
  
  .AlarmsList_DownloadButton__Kd49J:hover {
    background-color: #003a8a;
  }
  
  .AlarmsList_DownloadButton__Kd49J span {
    margin-left: 5px;
  }
  
  
  /* Loader Container */
  .AlarmsList_LoaderContainer__1c4OY {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust as needed */
  }
  /* Container for the filter controls */
.AlarmsList_FilterControls__1GTQ1 {
  display: flex;
  align-items: center;
}

/* Clear Filters Button */
.AlarmsList_ClearFiltersButton__3pht- {
  padding: 8px 16px;
  border-radius: 5px;
  background: #6c757d;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}
/* Blur effect when a dropdown filter is open */
.AlarmsList_TableBlurred__N3rY8 {
  filter: blur(2px);
}
.AlarmsList_ClearFiltersButton__3pht-:hover {
  background-color: #5a6268;
}
  /* Responsive Design */
  @media (max-width: 768px) {
    .AlarmsList_HeadRow__1aBYV {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .AlarmsList_DownloadButtonContainer__3XhOm {
      justify-content: center;
    }
    .AlarmsList_FilterControls__1GTQ1 {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .AlarmsList_ClearFiltersButton__3pht- {
      margin-bottom: 10px;
    }
  }
  
/* DropdownFilter.module.css */

/* Container for the filter button */
.DropdownFilter_FilterContainer__b5Jol {
  position: relative;
}

/* Filter button styling */
.DropdownFilter_FilterButton__2SqhZ {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
  transition: color 0.3s;
}

.DropdownFilter_FilterButton__2SqhZ:hover,
.DropdownFilter_FilterButton__2SqhZ:focus {
  color: #000;
  outline: none;
}

/* Dropdown container */
.DropdownFilter_FilterDropdown__2ZLpK {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 280px;
  animation: DropdownFilter_fadeIn__1bTov 0.2s ease-in-out;
}

/* Animation for dropdown appearance */
@keyframes DropdownFilter_fadeIn__1bTov {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Close button styling */
.DropdownFilter_CloseButton__1OUeB {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s;
}

.DropdownFilter_CloseButton__1OUeB:hover,
.DropdownFilter_CloseButton__1OUeB:focus {
  color: #444;
  outline: none;
}

/* Sorting buttons container */
.DropdownFilter_SortButtons__1XeEW {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

/* Individual sort button */
.DropdownFilter_SortButton__3IpGd {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  font-size: 16px;
  color: #555;
  transition: color 0.3s, background-color 0.3s;
}

.DropdownFilter_SortButton__3IpGd:hover,
.DropdownFilter_SortButton__3IpGd:focus {
  color: #000;
  background-color: #f0f0f0;
  border-radius: 4px;
  outline: none;
}

/* Time filter container */
.DropdownFilter_TimeFilter__2fSWu {
  display: flex;
  flex-direction: column;
}

/* Time input container */
.DropdownFilter_TimeInputContainer__3fzd6 {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.DropdownFilter_TimeInputContainer__3fzd6 label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

/* Time input field */
.DropdownFilter_TimeInput__1kNiY {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
}

.DropdownFilter_TimeInput__1kNiY:focus {
  border-color: #007bff;
  outline: none;
}

/* Apply button */
.DropdownFilter_ApplyButton__iwgOc {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.DropdownFilter_ApplyButton__iwgOc:hover,
.DropdownFilter_ApplyButton__iwgOc:focus {
  background-color: #0056b3;
  outline: none;
}

/* Clear button */
.DropdownFilter_ClearButton__322Ax {
  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.DropdownFilter_ClearButton__322Ax:hover,
.DropdownFilter_ClearButton__322Ax:focus {
  background-color: #5a6268;
  outline: none;
}

/* Checkbox filter container */
.DropdownFilter_CheckboxFilter__mgm3J {
  display: flex;
  flex-direction: column;
}

/* Search input container */
.DropdownFilter_SearchInputContainer__2vYVe {
  position: relative;
  margin-bottom: 10px;
}

.DropdownFilter_SearchIcon__3uS8i {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #aaa;
  font-size: 16px;
}

.DropdownFilter_SearchInput__1ISCA {
  width: 100%;
  padding: 10px 12px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
}

.DropdownFilter_SearchInput__1ISCA:focus {
  border-color: #007bff;
  outline: none;
}

/* Checkbox list styling */
.DropdownFilter_CheckboxList__21dvm {
  max-height: 160px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.DropdownFilter_CheckboxList__21dvm::-webkit-scrollbar {
  width: 8px;
}

.DropdownFilter_CheckboxList__21dvm::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.DropdownFilter_CheckboxList__21dvm::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

/* Individual checkbox label */
.DropdownFilter_CheckboxLabel__3rmGw {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.DropdownFilter_CheckboxLabel__3rmGw:hover {
  background-color: #f8f9fa;
}

.DropdownFilter_CheckboxLabel__3rmGw input {
  margin-right: 10px;
}

/* No options found message */
.DropdownFilter_NoOptions__1TIjI {
  padding: 15px;
  color: #777;
  text-align: center;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .DropdownFilter_FilterDropdown__2ZLpK {
    width: 90vw;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Adjustments for different dropdown positions */
.DropdownFilter_bottom-right__1Wkan {
  top: 100%;
  left: 0;
  margin-top: 10px;
}

.DropdownFilter_bottom-left__2G6RQ {
  top: 100%;
  right: 0;
  margin-top: 10px;
}

.DropdownFilter_top-right__3T3T2 {
  bottom: 100%;
  left: 0;
  margin-bottom: 10px;
}

.DropdownFilter_top-left__2kvFf {
  bottom: 100%;
  right: 0;
  margin-bottom: 10px;
}

/* Wrapper around the DatePicker to align with the HeadRow styles */
.DatePicker_datePickerWrapper__2Fwzh {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }
  
  /* DatePicker input, inheriting font styles from AlarmsList */
  .DatePicker_datePickerInput__2Ayg3 {
    width: 100%;
    max-width: 250px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em; /* Matches Label font size in AlarmsList */
    color: #333;
  }
  
  /* Styling the dropdown calendar */
  .DatePicker_rs-picker-menu__QG2ir {
    background-color: #ffffff; /* Matches AlarmsListContainer background */
    border: 1px solid #e0e0e0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); /* Slightly elevated shadow */
    border-radius: 8px; /* Matches AlarmsListContainer border radius */
  }
  
  /* Highlight selected date */
  .DatePicker_rs-picker-toggle-active__FUVx- {
    background-color: #004aac; /* Matches TitleSeparator color */
    color: #fff;
  }
  
  /* Hover effect for the DatePicker */
  .DatePicker_datePickerInput__2Ayg3:hover {
    border-color: #004aac;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .DatePicker_datePickerWrapper__2Fwzh {
      width: 100%;
      justify-content: flex-start;
    }
  }
  
/* src/graph/Graph.module.css */

/* Spinner Container for Centering */
.Graph_GraphSpinnerContainer__2bjiE {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/* Error Container for Displaying Errors */
.Graph_GraphErrorContainer__1EYql {
  text-align: center;
  padding: 10px 15px;
  color: #d9534f;
  margin-bottom: 20px;
  font-weight: bold;
  background-color: #f9d6d5;
  border: 1px solid #d9534f;
  border-radius: 4px;
}
/* Chart Wrapper */
.Graph_ChartWrapper__N3vuI {
  position: relative;
  flex: 1 1; /* Allow it to grow and fill the available space */
  margin: 20px 0;
}

/* Button Container for Export and AI inside the card */
.Graph_ButtonContainer__3fK77 {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  
}

/* Ensure the GraphContainer takes full control of the card */
.Graph_GraphContainer__3BbYb {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

/* Download and AI Buttons */
.Graph_DownloadButton__wIx5l{
  padding: 8px 16px;
  border-radius: 5px;
  background: #004aac; /* Primary theme color */
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.Graph_DownloadButton__wIx5l:hover{
  background-color: #003a8a; /* Darker shade on hover */
}

/* Ensures icon and text are aligned well in buttons */
.Graph_DownloadButton__wIx5l span {
  margin-left: 5px;
}

/* Retry Button for Error */
.Graph_RetryButton__2hz-H {
  margin: 15px 0;
  padding: 10px 15px;
  background-color: #004aac;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  min-width: 120px;
}

.Graph_RetryButton__2hz-H:hover {
  background-color: #003a8a;
}

@media (max-width: 768px) {
  .Graph_ButtonContainer__3fK77 {
    justify-content: center; /* Center buttons on smaller screens */
    grid-gap: 10px;
    gap: 10px; /* Reduce spacing between buttons */
    padding: 10px;
    flex-direction: column;
  }

  .Graph_DownloadButton__wIx5l {
    width: 100%; /* Make buttons full width on small screens */
    margin-bottom: 10px;
  }

  .Graph_GraphContainer__3BbYb {
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .Graph_RetryButton__2hz-H {
    width: 100%;
    margin-top: 10px; /* Separate the Retry button */
  }
}

/* AIButton.module.css */

/* Import Google Fonts */

/* AI Analysis Button */
.AIButton_AIButton__1xNPn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  font-family: 'Roboto', Arial, sans-serif;
  position: relative;
}

.AIButton_ButtonContent__FNQGR {
  padding: 12px 24px;
  border-radius: 30px;
  background: linear-gradient(45deg, #004aac, #007bff);
  transition: background 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 1em;
  position: relative;
  overflow: hidden; /* For the ripple effect */
}

.AIButton_ButtonContent__FNQGR:hover {
  background: linear-gradient(45deg, #003a8a, #006aff);
  box-shadow: 0 8px 20px rgba(0, 74, 172, 0.6);
}

/* Ripple Effect */
.AIButton_ButtonContent__FNQGR::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 0;
  transition: width 0.6s ease, height 0.6s ease, opacity 1s;
}

.AIButton_ButtonContent__FNQGR:active::after {
  width: 250%;
  height: 250%;
  opacity: 0;
  transition: 0s;
}

/* Icon Wrapper */
.AIButton_IconWrapper__1ctfQ {
  position: relative;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

/* Icon Styling */
.AIButton_Icon__wrQjH {
  font-size: 1.5em;
  transition: transform 0.3s ease, color 0.3s ease;
  color: #fff;
}

/* Bobbing Animation */
@keyframes AIButton_bob__3iYJj {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.AIButton_ButtonContent__FNQGR:hover .AIButton_Icon__wrQjH {
  animation: AIButton_bob__3iYJj 1s ease-in-out infinite;
}

/* Speech Bubble Styling */
.AIButton_SpeechBubble__3Hq3N {
  position: absolute;
  top: -60px;
  left: 25px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 10px 15px;
  opacity: 0;
  transform: translateY(10px) scale(0.9);
  transform-origin: bottom left;
  transition: opacity 0.4s ease, transform 0.4s ease;
  pointer-events: none;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.AIButton_SpeechBubble__3Hq3N::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 25px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.AIButton_ButtonContent__FNQGR:hover .AIButton_SpeechBubble__3Hq3N {
  opacity: 1;
  transform: translateY(0) scale(1);
}

/* Dot Styling */
.AIButton_Dot___J16p {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #004aac;
  border-radius: 50%;
  opacity: 0;
  animation: AIButton_blink__2sJkN 1.5s infinite both, AIButton_colorChange__ppfaj 3s infinite;
}

.AIButton_Dot___J16p:nth-child(1) {
  animation-delay: 0s;
}

.AIButton_Dot___J16p:nth-child(2) {
  animation-delay: 0.3s;
}

.AIButton_Dot___J16p:nth-child(3) {
  animation-delay: 0.6s;
}

/* Dot Blink Animation */
@keyframes AIButton_blink__2sJkN {
  0% { opacity: 0.2; transform: scale(0.8); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { opacity: 0.2; transform: scale(0.8); }
}

/* Dot Color Change */
@keyframes AIButton_colorChange__ppfaj {
  0% { background-color: #004aac; }
  50% { background-color: #007bff; }
  100% { background-color: #004aac; }
}

/* Button Text */
.AIButton_ButtonText__2RyDQ {
  position: relative;
  z-index: 1;
}

/* Loader Text */
.AIButton_ModalContent__2w40x p {
  margin-top: 20px;
  font-size: 1.2em;
  color: #004aac;
  animation: AIButton_pulse__3xdf0 2s infinite;
}

/* Pulse Animation */
@keyframes AIButton_pulse__3xdf0 {
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
}

/* Modal Overlay */
.AIButton_ModalOverlay__gKKZI {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

/* Modal Content for the loader */
.AIButton_ModalContent__2w40x {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  z-index: 100;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
  max-width: 450px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', Arial, sans-serif;
}

/* Media Query for Small Screens */
@media (max-width: 600px) {
  .AIButton_ButtonContent__FNQGR {
    padding: 10px 18px;
    font-size: 0.9em;
  }

  .AIButton_SpeechBubble__3Hq3N {
    top: -70px;
    left: 15px;
  }

  .AIButton_SpeechBubble__3Hq3N::after {
    left: 20px;
  }
}

/* src/ai/AIComponents.module.css */

/* Import Google Fonts */

/* Modal Overlay */
.AIComponents_ModalOverlay__OVw-V {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
  z-index: 99;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px); /* Increased blur effect */
}

/* AI Modal */
.AIComponents_AIModal__GRNMJ {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 40px 30px;
  border-radius: 15px;
  z-index: 100;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  width: 90%;
  max-height: 85vh;
  overflow-y: auto;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
}

/* Close Button */
.AIComponents_CloseButton__1E_i_ {
  position: absolute;
  top: 20px;
  right: 25px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  color: #aaa;
  cursor: pointer;
  transition: color 0.2s ease;
}

.AIComponents_CloseButton__1E_i_:hover {
  color: #000;
}

/* AI Content */
.AIComponents_AIContent__bwqpo {
  text-align: left;
  max-height: 75vh;
  overflow-y: auto;
  padding-right: 5px; /* Space for custom scrollbar */
}

/* Modal Title */
.AIComponents_ModalTitle__1HyA- {
  display: flex;
  align-items: center;
  font-size: 2em;
  color: #004aac;
  margin-bottom: 25px;
  font-weight: 700;
}

.AIComponents_ModalTitle__1HyA- svg {
  margin-right: 15px;
  font-size: 1.2em;
}

/* Horizontal Line */
.AIComponents_AIContent__bwqpo hr {
  border: none;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 30px;
}

/* AI Response */
.AIComponents_AIResponse__1Y0in {
  color: #333;
  line-height: 1.8;
  font-size: 1em;
}

/* Headings */
.AIComponents_AIResponse__1Y0in h2 {
  color: #003a8a;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1.8em;
  font-weight: 700;
}

.AIComponents_AIResponse__1Y0in h3 {
  color: #004aac;
  margin-top: 25px;
  margin-bottom: 18px;
  font-size: 1.6em;
  font-weight: 600;
}

.AIComponents_AIResponse__1Y0in h4 {
  color: #005bd1;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 1.4em;
  font-weight: 500;
}

.AIComponents_AIResponse__1Y0in h5 {
  color: #006aff;
  margin-top: 15px;
  margin-bottom: 12px;
  font-size: 1.2em;
  font-weight: 500;
}

/* Paragraphs */
.AIComponents_AIResponse__1Y0in p {
  margin-bottom: 20px;
}

/* Lists */
.AIComponents_AIResponse__1Y0in ul {
  list-style-type: disc;
  margin-left: 30px;
  margin-bottom: 20px;
}

.AIComponents_AIResponse__1Y0in li {
  margin-bottom: 10px;
}

/* Interpretation Section */
.AIComponents_AIResponse__1Y0in .AIComponents_interpretation__3uba2 {
  margin-top: 30px;
  padding: 25px;
  background-color: #f0f7ff;
  border-left: 5px solid #004aac;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.AIComponents_AIResponse__1Y0in .AIComponents_interpretation__3uba2 h5 {
  margin-top: 0;
}

/* Quotes */
.AIComponents_AIResponse__1Y0in blockquote {
  margin: 20px 0;
  padding: 15px 20px;
  background-color: #f9f9f9;
  border-left: 5px solid #ddd;
  font-style: italic;
  color: #666;
}

/* Loader */
.AIComponents_Loader__3ZBXk {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

/* Heatmap Container */
.AIComponents_HeatmapContainer__1PtkB {
  text-align: center;
  margin-top: 40px;
}

.AIComponents_HeatmapContainer__1PtkB img {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.AIComponents_HeatmapContainer__1PtkB img:hover {
  transform: scale(1.02);
}

/* Button Container */
.AIComponents_ButtonContainer__2kiAI {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  flex-wrap: wrap;
}

/* Buttons */
.AIComponents_ButtonContainer__2kiAI button {
  padding: 14px 30px;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 30px;
  cursor: pointer;
  min-width: 150px;
  font-size: 1em;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.2s ease;
  border: none;
  font-weight: 500;
}

/* Primary Button */
.AIComponents_PrimaryButton__3U59Y {
  background-color: #004aac;
  color: #fff;
}

.AIComponents_PrimaryButton__3U59Y:hover {
  background-color: #003a8a;
  box-shadow: 0 4px 15px rgba(0, 74, 172, 0.4);
}

/* Secondary Button */
.AIComponents_SecondaryButton__2NLz1 {
  background-color: #f5f5f5;
  color: #333;
}

.AIComponents_SecondaryButton__2NLz1:hover {
  background-color: #e5e5e5;
}

/* Disabled Button */
.AIComponents_ButtonContainer__2kiAI button:disabled {
  background-color: #cccccc;
  color: #777;
  cursor: not-allowed;
}

/* Scrollbar Styling */
.AIComponents_AIContent__bwqpo::-webkit-scrollbar {
  width: 10px;
}

.AIComponents_AIContent__bwqpo::-webkit-scrollbar-thumb {
  background-color: #bbbbbb;
  border-radius: 5px;
}

.AIComponents_AIContent__bwqpo::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}

.AIComponents_AIContent__bwqpo::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Animations */
@keyframes AIComponents_fadeIn__2QUPY {
  from { opacity: 0; transform: translate(-50%, -55%); }
  to { opacity: 1; transform: translate(-50%, -50%); }
}

.AIComponents_AIModal__GRNMJ {
  animation: AIComponents_fadeIn__2QUPY 0.4s ease-out;
}

/* Media Query for Small Screens */
@media (max-width: 600px) {
  .AIComponents_AIModal__GRNMJ {
    width: 95%;
    padding: 30px 20px;
  }

  .AIComponents_ModalTitle__1HyA- {
    font-size: 1.6em;
  }

  .AIComponents_CloseButton__1E_i_ {
    top: 15px;
    right: 15px;
  }

  .AIComponents_ButtonContainer__2kiAI {
    flex-direction: column;
    align-items: stretch;
  }

  .AIComponents_ButtonContainer__2kiAI button {
    width: 100%;
    margin: 5px 0;
  }
}

/* src/stats/AreaInStats.module.css */

/* Main container for the AreaInStats component */
.AreaInStats_AreaInStatsContainer__2bzDi {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  
  /* Container for each statistics item */
  .AreaInStats_StatsContainer__1_-hp {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 0; /* Remove padding to allow the Graph component to control it */
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Prevent overflow */
  }
  
  /* Label styling */
  .AreaInStats_StatsLabel__35J4E {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    padding: 20px; /* Add padding to the label */
  }
  
  /* Separator under the title */
  .AreaInStats_TitleSeparator__ERKBg {
    height: 2px;
    background-color: #004aac;
    width: 100%;
  }
  
  /* Graph container */
  .AreaInStats_GraphContainer__65wyG {
    position: relative;
    height: 400px; /* Adjust the height as needed */
    margin-bottom: 20px;
  }
  
  /* AlarmsList Container */
  .AreaInStats_AlarmsListContainer__2WbM1 {
    margin-bottom: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .AreaInStats_StatsLabel__35J4E {
      font-size: 1em;
      padding: 15px;
    }
  
    .AreaInStats_StatsContainer__1_-hp {
      margin-bottom: 15px;
    }
  
    .AreaInStats_GraphContainer__65wyG {
      height: 300px; /* Adjust the height for smaller screens */
    }
  }
  
/* src/stats/GlobalDashboardStats.module.css */

/* Container for each statistics item */
.GlobalDashboardStats_StatsContainer__3UO1i {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Label styling */
  .GlobalDashboardStats_StatsLabel__2hBPh {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Separator under the title */
  .GlobalDashboardStats_TitleSeparator__1RhcH {
    height: 2px;
    background-color: #004aac;
    width: 100%;
    margin-bottom: 15px;
  }
  
  /* Graph container */
  .GlobalDashboardStats_GraphContainer__rHTp4 {
    position: relative;
    height: 400px; /* Adjust the height as needed */
    margin-bottom: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .GlobalDashboardStats_StatsContainer__3UO1i {
      padding: 15px;
    }
  
    .GlobalDashboardStats_StatsLabel__2hBPh {
      font-size: 1em;
    }
  }
  
/* src/stats/GlobalDashboardStats.module.css */

/* Container for each statistics item */
.HatWearingStats_StatsContainer__1c9k1 {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Label styling */
  .HatWearingStats_StatsLabel__2JxjJ {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Separator under the title */
  .HatWearingStats_TitleSeparator__WBujC {
    height: 2px;
    background-color: #004aac;
    width: 100%;
    margin-bottom: 15px;
  }
  
  /* Graph container */
  .HatWearingStats_GraphContainer__qODUZ {
    position: relative;
    height: 400px; /* Adjust the height as needed */
    margin-bottom: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .HatWearingStats_StatsContainer__1c9k1 {
      padding: 15px;
    }
  
    .HatWearingStats_StatsLabel__2JxjJ {
      font-size: 1em;
    }
  }
  
/* src/cards/LastEventDateCard.module.css */

/* Container for the LastEventDateCard component */
.LastEventDateCard_LastEventDateCardContainer__2DmKI {
    width: 350px;
    margin-right: 10px;
    margin-bottom: 15px;
    display: inline-block;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .LastEventDateCard_LastEventDateCardContainer__2DmKI {
      width: 100%;
      margin-right: 0;
      display: block;
    }
  }
  
/* src/stats/GlobalDashboardStats.module.css */

/* Container for each statistics item */
.LyingPersonStats_StatsContainer__3_YjO {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Label styling */
  .LyingPersonStats_StatsLabel__3tUgh {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Separator under the title */
  .LyingPersonStats_TitleSeparator__3O7pf {
    height: 2px;
    background-color: #004aac;
    width: 100%;
    margin-bottom: 15px;
  }
  
  /* Graph container */
  .LyingPersonStats_GraphContainer__3QgOW {
    position: relative;
    height: 400px; /* Adjust the height as needed */
    margin-bottom: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .LyingPersonStats_StatsContainer__3_YjO {
      padding: 15px;
    }
  
    .LyingPersonStats_StatsLabel__3tUgh {
      font-size: 1em;
    }
  }
  
/* src/stats/GlobalDashboardStats.module.css */

/* Container for each statistics item */
.OverloadedStats_StatsContainer__2wjkl {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Label styling */
  .OverloadedStats_StatsLabel__30koM {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Separator under the title */
  .OverloadedStats_TitleSeparator__1kQnS {
    height: 2px;
    background-color: #004aac;
    width: 100%;
    margin-bottom: 15px;
  }
  
  /* Graph container */
  .OverloadedStats_GraphContainer__3COkF {
    position: relative;
    height: 400px; /* Adjust the height as needed */
    margin-bottom: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .OverloadedStats_StatsContainer__2wjkl {
      padding: 15px;
    }
  
    .OverloadedStats_StatsLabel__30koM {
      font-size: 1em;
    }
  }
  
/* src/stats/GlobalDashboardStats.module.css */

/* Container for each statistics item */
.CollisionStats_StatsContainer__Qb87a {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Label styling */
  .CollisionStats_StatsLabel__OI92S {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Separator under the title */
  .CollisionStats_TitleSeparator__2Dt8w {
    height: 2px;
    background-color: #004aac;
    width: 100%;
    margin-bottom: 15px;
  }
  
  /* Graph container */
  .CollisionStats_GraphContainer__2nsRw {
    position: relative;
    height: 400px; /* Adjust the height as needed */
    margin-bottom: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .CollisionStats_StatsContainer__Qb87a {
      padding: 15px;
    }
  
    .CollisionStats_StatsLabel__OI92S {
      font-size: 1em;
    }
  }
  
/* CSS Variables */
:root {
    --menu-width: 220px;
    --devices-menu-width: 0px;
    --header-height: 60px;
    --container-padding: 20px;
    --button-hover-bg: #21a1f1;
    --default-padding: 10px;
    --shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Layout Adjustments */

.Home_HomeContainer__1uvu0 {
    padding: var(--container-padding);
    margin-top: var(--header-height);
    position: absolute;
    left: calc(var(--devices-menu-width));
    width: calc(100% - var(--devices-menu-width));
}

/* Common Utility Classes */
.Home_flex-center__3oOZX {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Home_flex-space-between__2WbJC {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Item Container */
.Home_StatsItemContainer__2aT9B {
    background-color: #fff;
    padding: var(--default-padding);
    margin-bottom: var(--default-padding);
    border-radius: 8px;
    box-shadow: var(--shadow);
    transition: all 0.3s ease;
}

.Home_StatsItemContainer__2aT9B > label {
    font-weight: bold;
    font-size: 16px;
    padding: var(--default-padding) 0;
    display: block;
}

/* Header Row */
.Home_HeadRow__mjLwW {
    @extend .flex-space-between;
    padding-right: var(--default-padding);
}

.Home_HeadRow__mjLwW > label {
    font-weight: bold;
    font-size: 16px;
}

/* Separator */
.Home_HomeTitleSeparator__3CpdH {
    width: 100%;
    height: 1px;
    background-color: #D8D8D8;
    margin: 10px 0;
    border-bottom: 1px solid #ddd;
}

/* Basic Table Styles */
.Home_Table__1xaq8 {
    width: 100%;
}

.Home_Table__1xaq8 th, .Home_Table__1xaq8 td {
    padding: 8px;
    text-align: center;
}

.Home_HomeTableContainer__2r18Z th:first-of-type,
.Home_HomeTableContainer__2r18Z td:first-of-type {
    border-left: none;
}

.Home_HomeTableContainer__2r18Z th:last-of-type,
.Home_HomeTableContainer__2r18Z td:last-of-type {
    border-right: none;
}

.Home_HomeTableContainer__2r18Z th, .Home_HomeTableContainer__2r18Z td {
    font-size: 13px;
    border: 1px solid #eaeaea;
    padding: 6px;
}

/* Recent Events */
.Home_HomeRecentEventRow__1J_Ty > td {
    background-color: #fc525241;
}

/* Download and AI Buttons */
.Home_ContainerDownloadButton__2B9pr {
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 10px;
    padding: var(--default-padding);
}

/* Retry Button */
.Home_RetryButton__5qF0E {
    margin: 15px 0;
    padding: 10px 15px;
    background-color: #fefefe;
    border: 1px solid lightgray;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    min-width: 120px;
}

.Home_RetryButton__5qF0E:hover {
    background-color: lightgray;
}

/* Tree Menu Container */
.Home_TreeContainer__3oQR3 {
    position: fixed;
    width: var(--devices-menu-width);
    height: calc(100% - var(--header-height));
    left: var(--menu-width);
    background-color: white;
    padding: var(--default-padding);
    overflow-y: auto;
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.32);
    z-index: 160;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .Home_StatsItemContainer__2aT9B {
        padding: 15px;
    }

    .Home_TreeContainer__3oQR3 {
        width: 100%;
        height: auto;
        position: relative;
    }

    .Home_RetryButton__5qF0E {
        width: 100%;
        margin-top: 10px;
    }
}

/* General container styles */
.SignUp_container__1T2uB {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  animation: SignUp_fadeIn__3xmO6 0.5s ease-in-out;
}

/* Company Logo */
.SignUp_logo__1KamC {
  width: 150px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.SignUp_logo__1KamC:hover {
  transform: scale(1.1); /* Slight scale-up on hover */
}

/* Title of the app */
.SignUp_title__2jwAi {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 600;
  color: #333;
}

/* Form container */
.SignUp_form__EncWB {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.SignUp_form__EncWB:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

/* Input container */
.SignUp_inputContainer__2j2il {
  margin-bottom: 20px;
}

/* Label for inputs */
.SignUp_label__22ZtJ {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
}

/* Input fields */
.SignUp_input__hKJhp {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.SignUp_input__hKJhp:focus {
  border-color: #007bff;
  outline: none;
}

/* Row for first name and last name */
.SignUp_row__3Ai5m {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SignUp_row__3Ai5m .SignUp_inputContainer__2j2il {
  flex: 0 0 48%;
}

@media (max-width: 600px) {
  .SignUp_row__3Ai5m .SignUp_inputContainer__2j2il {
    flex: 0 0 100%;
  }
}

/* Checkbox container */
.SignUp_checkboxContainer__2Caf8 {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.SignUp_checkboxLabel__2tqe- {
  display: flex;
  align-items: center;
}

.SignUp_checkbox__KDcwA {
  margin-right: 10px;
}

/* Submit button */
.SignUp_submitButton__1MVTO {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.SignUp_submitButton__1MVTO:hover {
  background-color: #0056b3;
  transform: scale(1.02);
}

.SignUp_submitButton__1MVTO:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Spinner styles */
.SignUp_spinner__2Ehbt {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: SignUp_spin__1YpZ4 1s linear infinite;
}

@keyframes SignUp_spin__1YpZ4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Error and success messages */
.SignUp_errorMessage__TOrWn {
  color: #e74c3c;
  margin-bottom: 15px;
  font-weight: 500;
}

.SignUp_successMessage__3_Cuw {
  color: #28a745;
  margin-bottom: 15px;
  font-weight: 500;
}

/* Password field styles */
.SignUp_passwordContainer__3IFUy {
  display: flex;
  align-items: center;
}

.SignUp_toggleButton__3v2Jj {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.SignUp_toggleButton__3v2Jj:hover {
  background-color: #0056b3;
}

/* Modal overlay that covers the entire background */
.SignUp_modalOverlay__2bkTZ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: SignUp_fadeIn__3xmO6 0.3s ease-in-out;
}

/* Modal container */
.SignUp_modal__2ZK9A {
  background-color: #fff;
  padding: 30px;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: SignUp_slideDown__2xifs 0.4s ease;
}

/* Close button (top-right corner) */
.SignUp_close__Gd5jC {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.SignUp_close__Gd5jC:hover {
  color: #e74c3c;
  transform: rotate(90deg) scale(1.2);
}

/* Modal content */
.SignUp_modalContent__3dp-D {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.6;
}

.SignUp_modalContent__3dp-D .SignUp_fa-check-circle__2pkU5 {
  color: #28a745;
  font-size: 48px;
  margin-bottom: 10px;
}

.SignUp_modalContent__3dp-D .SignUp_fa-times-circle__RaBU2 {
  color: #dc3545;
  font-size: 48px;
  margin-bottom: 10px;
}

/* Back button inside the modal */
.SignUp_backButton__167Sq {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-right: 10px;
}

.SignUp_backButton__167Sq:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Send Email button */
.SignUp_sendEmailButton__1XroU {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.SignUp_sendEmailButton__1XroU:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.SignUp_sendEmailButton__1XroU:active {
  transform: scale(0.98);
}

/* Animations */
@keyframes SignUp_fadeIn__3xmO6 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes SignUp_slideDown__2xifs {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive modal on small devices */
@media (max-width: 768px) {
  .SignUp_modal__2ZK9A {
    width: 90%;
    padding: 20px;
  }

  .SignUp_close__Gd5jC {
    font-size: 20px;
    top: 10px;
    right: 15px;
  }
}

/* Responsive form */
@media (max-width: 600px) {
  .SignUp_form__EncWB {
    padding: 20px;
  }

  .SignUp_row__3Ai5m .SignUp_inputContainer__2j2il {
    flex: 0 0 100%;
    margin-bottom: 15px;
  }

  .SignUp_input__hKJhp {
    padding: 8px;
  }
}

/*
src/GlobalView/styles/Tree.module.css
*/

:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
}

.Tree_treeContainer__3eF1l {
    flex-grow: 1;
    padding: 10px;
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow var(--transition-speed) ease, background-color var(--transition-speed) ease;
    overflow-y: auto; /* Allow vertical scrolling when needed */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.Tree_treeContainer__3eF1l:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Add subtle hover effect */
}
.Tree_treeContainer__3eF1l::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 4px;
}

.Tree_treeComponent__30rib {
    height: 100%; /* Ensure it takes full height */
    width: 100%; /* Full width of parent container */
    max-height: 100vh; /* Constrain height to viewport */
    padding: 10px 0;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}


/*
src/GlobalView/Treeutils/components/styles/TreeNode.module.css
*/

/* Root variables for easy customization */
:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
}


/* Node content containing label and buttons */
.TreeNode_nodeContent__2_flp {
    display: flex;
    align-items: center;
    width: 100%;
    
}

.TreeNode_nodeLabelContainer__YsjLs {
    display: flex;
    align-items: center;
}

/* Style adjustments for the sort button */
.TreeNode_sortButton__1T2J2 {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 5px;
    color: #555;
    display: flex;
    align-items: center;
  }
  
  .TreeNode_sortButton__1T2J2:hover {
    color: #000;
  }
  
  /* Initially hide the sort button */
.TreeNode_treeNode__2khb4 .TreeNode_sortButton__1T2J2 {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
  /* Show the sort button when the node is hovered */
  .TreeNode_treeNode__2khb4:hover .TreeNode_sortButton__1T2J2 {
    opacity: 1;
    pointer-events: auto;
  }

  /* Expanded node icon */
.TreeNode_expanded__3BFYX .TreeNode_arrowicon__1CyxH {
    transform: rotate(90deg);
}
.TreeNode_treeNode__2khb4.TreeNode_expanded__3BFYX {
  max-height: 1000px; /* Set a large enough value to ensure smooth transition */
  opacity: 1;   
}

/* Collapsed node icon */
.TreeNode_collapsed__3Gz6N .TreeNode_arrowicon__1CyxH {
    transform: rotate(0deg);
}

.TreeNode_treeNode__2khb4.TreeNode_collapsed__3Gz6N {
    opacity: 0.8;
  }

  /* Leaf node icon */
.TreeNode_leaf__E6CH6 .TreeNode_arrowicon__1CyxH {
    font-size: 10px;
    transform: none;
    color: #aaa;
}

/* Icon styling */
.TreeNode_arrowicon__1CyxH {
    width: 18px;
    height: 18px;
    text-align: center;
    margin-right: 8px;
    font-size: 12px;
    transition: transform var(--transition-speed) ease;
}

.TreeNode_companyIcon__1jX0R {
    width: 18px;
    height: 18px;
    text-align: center;
    margin-right: 8px;
    font-size: 12px;
    transition: transform var(--transition-speed) ease;
    color: #1e1155;
}

.TreeNode_securispotIcon__1Ex20{
    width: 18px;
    height: 18px;
    text-align: center;
    margin-right: 8px;
    font-size: 12px;
    transition: transform var(--transition-speed) ease;
    color: #0056b3;
}


.TreeNode_defaultIcon__-AsHN{
    width: 18px;
    height: 18px;   
    text-align: left;
    margin-right: 8px;
    font-size: 12px;
    transition: transform var(--transition-speed) ease;
    color: #585b5e; 
}

/* Edit input field */
.TreeNode_editInput__3iwIM {
    width: 100%;
    padding: 4px 8px;
    font-size: 14px;
}

/* Node label */
.TreeNode_nodeLabel__irOz4 {
    flex-grow: 1;
    font-size: 14px;
    color: #333; /* Darker color for better readability */
}   


/* Styling each node in the tree */
.TreeNode_treeNode__2khb4 {
    position: relative;
    padding: 8px 10px;
    margin: 4px 0; /* Adjust margins for tighter spacing */
    border-radius: 4px;
    transition: max-height 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    opacity: 1;
    overflow: hidden;
}


.TreeNode_treeNode__2khb4:hover {
    background-color: #eef5ff; /* Soft blue background for hover */
    /* Remove transform: translateX(3px); */
    /* Maybe add a subtle box-shadow or a slight vertical translation instead */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

/* Highlight when dropping is not allowed on Securispot */
.TreeNode_cannotDrop__3sXx4 {
    background-color: rgba(255, 0, 0, 0.3); /* Red semi-transparent background */
  }

  .TreeNode_securispotIconRed__MJkgX {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    font-size: 12px;
    transition: transform 0.3s ease;
    color: red;
  }
  
  .TreeNode_securispotIconBlue__1nEeh {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    font-size: 12px;
    transition: transform 0.3s ease;
    color: blue;
  }
  
  /* Root variables for easy customization */
:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
}

  
  /* ActionButtons.module.css */

.ActionButtons_actionButtons__37pXJ {
    margin-left: auto;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
  }

  
  .ActionButtons_actionButton__2zbwW {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 1rem; /* Use relative units for responsiveness */
    transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ActionButtons_actionButton__2zbwW:hover {
    transform: scale(1.1);
  }
  
  .ActionButtons_actionButton__2zbwW:focus {
    outline: none;
  }
  
  .ActionButtons_actionButton__2zbwW:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Edit button styling */
  .ActionButtons_editButton__1fZ6Z {
    color: var(--edit-button-color, #007bff);
  }
  
  .ActionButtons_editButton__1fZ6Z:hover {
    color: var(--edit-button-hover-color, #0056b3);
  }
  
  /* Delete button styling */
  .ActionButtons_deleteButton__3WFqX {
    color: var(--delete-button-color, #dc3545);
  }
  
  .ActionButtons_deleteButton__3WFqX:hover {
    color: var(--delete-button-hover-color, #a71d2a);
  }
  
  /* Add button styling */
  .ActionButtons_addButton__rRans {
    color: var(--add-button-color, #28a745);
  }
  
  .ActionButtons_addButton__rRans:hover {
    color: var(--add-button-hover-color, #1e7e34);
  }
:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
}



/* AssociateButton.module.css */

.AssociateButton_associateButtonContainer__3M4x3 {
    margin-left: auto;
  }

  .AssociateButton_associateButton__1kOJw {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 16px;
    color: red; /* Bootstrap info color */
    transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .AssociateButton_associateButton__1kOJw:hover {
    transform: scale(1.1);
    color: darkred; /* Darker info color */
  }
  
  .AssociateButton_associateButton__1kOJw.AssociateButton_associated__3HaLa {
    color: #17a2b8; /* Change to Bootstrap info color for associated */
  }
  
  .AssociateButton_associateButton__1kOJw.AssociateButton_associated__3HaLa:hover {
    color: #117a8b; /* Darker info color */
  }
  
  .AssociateButton_associateButton__1kOJw:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

.ConfirmationModal_modalOverlay__3fIhb {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ConfirmationModal_modalContent__1fplz {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .ConfirmationModal_modalInput__3QAbW {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .ConfirmationModal_buttonGroup__2-3Iz {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .ConfirmationModal_confirmButton__2exul {
    background-color: #28a745;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .ConfirmationModal_cancelButton__wk8rf {
    background-color: #dc3545;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* New CSS class to style the error message */
  .ConfirmationModal_errorText__7Ae6T {
    color: red;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
:root {
  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --background-color: #f0f0f0;
  --tree-node-bg: #f8f8f8;
  --tree-node-hover-bg: #e6f7ff;
  --table-header-bg: #f1f1f1;
  --table-row-hover-bg: #f7f7f7;
  --border-color: #ddd;
  --border-radius: 8px;
  --transition-speed: 0.3s;
  --table-header-gradient-start: #f1f1f1;
  --table-header-gradient-end: #e8e8ec;
  --table-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  --cell-padding: 12px;
  --font-family-base: 'Arial', sans-serif;
}

.Table_tableContainer__dNR-t {
  width: 100%;
  margin: auto;
  padding: 1rem;
}

.Table_filterContainer__mueQH {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.Table_tableWrapper__ArWzG {
  overflow: auto;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--table-shadow);
}

.Table_styledTable__26Vsk {
  width: 100%;
  border-collapse: collapse;
  font-family: var(--font-family-base);
  font-size: 0.9rem;
}

.Table_styledTable__26Vsk thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: linear-gradient(
    to bottom,
    var(--table-header-gradient-start),
    var(--table-header-gradient-end)
  );
  z-index: 10;
}

.Table_tableHeader__3eWSq {
  padding: var(--cell-padding);
  text-align: left;
  border-bottom: 2px solid var(--border-color);
  color: #333;
  font-weight: bold;
  transition: background-color var(--transition-speed) ease-in-out;
}

.Table_tableHeader__3eWSq:hover {
  background-color: var(--background-color);
}

.Table_tableRow__3dUR_ {
  cursor: grab;
  transition: background-color var(--transition-speed) ease-in-out,
    transform 0.2s ease-in-out;
}

.Table_tableRow__3dUR_:nth-child(even) {
  background-color: var(--tree-node-bg);
}

.Table_tableRow__3dUR_:hover:not(.Table_nonDraggableRow__OKuBe) {
  background-color: var(--tree-node-hover-bg);
  transform: scale(1.01);
}

.Table_nonDraggableRow__OKuBe {
  cursor: default;
  background-color: var(--tree-node-bg);
  transition: background-color var(--transition-speed) ease-in-out;
}

.Table_nonDraggableRow__OKuBe:hover {
  background-color: var(--background-color);
  transform: none; /* No scale effect for non-draggable rows */
}

.Table_draggingRow__vvp_i {
  cursor: grabbing;
  background-color: rgba(0, 128, 255, 0.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.Table_tableCell__3tKrF {
  padding: var(--cell-padding);
  border-bottom: 1px solid var(--border-color);
  text-align: left;
  color: #555;
}

.Table_hiddenColumn__3bDqm {
  display: none;
}

.Table_noData__3SiDp {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #888;
}

.Table_noDataContent__1QDB1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.Table_noDataContent__1QDB1 span {
  font-size: 2em;
}

/* dragImage component  */

.Table_dragImage__FQJFF {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}
:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
  
    /* Additional optional variables if you like */
    --info-color: #17a2b8;
    --info-color-dark: #117a8b;
    --danger-color: red;
    --danger-color-dark: darkred;
  }
  
  /* Container for the associate button, aligned to the right */
  .AssociateButton_associateButtonContainer__2NRj5 {
    margin-left: auto; /* pushes other content left */
  }
  
  /* Main styling for the associate button */
  .AssociateButton_associateButton__1SPFa {
    background: none;
    border: none;
    padding: 0;
    font-size: 16px;
    color: var(--danger-color); /* Default color when not associated */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* Smooth transitions */
    transition: color var(--transition-speed) ease,
                transform var(--transition-speed) ease;
  }
  
  /* Hover effect for the button */
  .AssociateButton_associateButton__1SPFa:hover {
    transform: scale(1.1);
    color: var(--danger-color-dark); /* Darker color on hover */
  }
  
  /* If the item is associated, override the color */
  .AssociateButton_associateButton__1SPFa.AssociateButton_associated__2loMj {
    color: var(--info-color); /* Your Bootstrap info color */
  }
  
  /* Hover state for associated button */
  .AssociateButton_associateButton__1SPFa.AssociateButton_associated__2loMj:hover {
    color: var(--info-color-dark); /* Darker info color */
  }
  
  /* Disabled state for the button */
  .AssociateButton_associateButton__1SPFa:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
/*
src/GlobalView/styles/GlobaleView.module.css
*/

/* Root variables for easy customization */
:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;

    /* Additional variables for cohesive design */
    --box-shadow-base: 0 2px 10px rgba(0, 0, 0, 0.05);
    --highlight-color: yellow;
    --text-color-primary: #333;
    --text-color-secondary: #555;
    --input-bg: #fff;
}

/* General container styling for the global view */
.GlobaleView_container__3rNqM {
    display: flex;
    height: 100vh;                 /* fill the entire viewport height */
    width: 100%;
    overflow: hidden;              /* hide scroll on the global container */
    background-color: var(--background-color);
    transition: background-color var(--transition-speed) ease-in-out;
}

/* Collapsible container for optional toggled content */
.GlobaleView_collapsibleContainer__t41DI {
    overflow: hidden;
    max-height: 0;                 /* collapsed by default */
    opacity: 0;                    /* invisible by default */
    transition: max-height var(--transition-speed) ease,
                opacity var(--transition-speed) ease;
}

/* Node content container to neatly space child elements */
.GlobaleView_nodeContentContainer__3m82Z {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .GlobaleView_container__3rNqM {
        flex-direction: column;
        height: auto;
    }
}

/* Basic table styling */
table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;         /* Or var(--background-color) if preferred */
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: var(--box-shadow-base);
}

caption {
    caption-side: top;
    font-size: 1.5em;
    margin-bottom: 10px;
    color: var(--primary-color);
    font-weight: bold;
}

/* Table header styling */
th {
    background-color: var(--table-header-bg);
    font-weight: bold;
    padding: 12px;
    text-align: left;
    border-bottom: 2px solid var(--border-color);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    color: var(--text-color-primary);
}

/* Table data cell styling */
td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
    transition: background-color var(--transition-speed) ease;
    color: var(--text-color-secondary);
}

/* Hover effect for rows */
tr:hover td {
    background-color: var(--table-row-hover-bg);
}

/* Zebra striping for even rows */
tr:nth-child(even) td {
    background-color: var(--tree-node-bg);
}

/* Responsive table adjustments for smaller screens */
@media (max-width: 768px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    th {
        /* Hide table headers (they’ll be replaced by data-label) */
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    td {
        position: relative;
        padding-left: 50%;
        text-align: left;
    }

    td::before {
        position: absolute;
        top: 12px;
        left: 10px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
        color: #666;
        content: attr(data-label);
    }

    tr {
        margin-bottom: 15px;
        border-bottom: 2px solid var(--border-color);
    }
}

/* Highlight class for special emphasis */
.GlobaleView_highlight__3ZOs6 {
    background-color: var(--highlight-color);
    font-weight: bold;
}

/* Input field styling */
input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    margin-bottom: 10px;
    background-color: var(--input-bg);
}

/* Button styling */
.GlobaleView_button__3tszZ {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease, 
                transform 0.1s ease;
}

.GlobaleView_button__3tszZ:hover {
    background-color: var(--primary-hover-color);
}

.GlobaleView_button__3tszZ:active {
    transform: scale(0.95);
}

/* Scrollbar styling */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--primary-hover-color);
}

/* Optionally hide columns */
.GlobaleView_hiddenColumn__31v9M {
    display: none;
}

/*
src/GlobalView/styles/TabelSection.module.css
*/

:root {
  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --background-color: #f0f0f0;
  --tree-node-bg: #f8f8f8;
  --tree-node-hover-bg: #e6f7ff;
  --table-header-bg: #f1f1f1;
  --table-row-hover-bg: #f7f7f7;
  --border-color: #ddd;
  --border-radius: 8px;
  --transition-speed: 0.3s;

  /* Extra variables for a polished tab section */
  --tab-bg: #f4f4f4;
  --tab-hover-bg: #e0f3ff;
  --tab-active-bg: #0073e6;
  --tab-font-color: #555;
  --tab-hover-font-color: #0073e6;
  --tab-active-font-color: #fff;
  --box-shadow-light: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Table Content Section */
.TabelSection_tableContent__2dyX- {
  margin-top: 10px;
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-light);
  padding: 1rem; /* Optional: Add some internal padding for breathing room */
}

/* Tabs Container */
.TabelSection_tabsContainer__2-NY9 {
  display: flex;
  flex-direction: row; /* Aligns tabs horizontally (not vertically) */
  grid-gap: 10px;
  gap: 10px;          /* Adds spacing between the tabs */
  margin-right: 16px; /* Space between tabs and table content */
  padding: 8px 0;     /* Vertical padding around the tabs */
  background-color: var(--background-color);
  border-right: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-light);
  overflow-x: auto;   /* Allow horizontal scroll if tabs exceed container width */
}

/* Tab Buttons */
.TabelSection_tabButton__2uwYs {
  background-color: var(--tab-bg);
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  color: var(--tab-font-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  transition: background-color var(--transition-speed) ease,
    color var(--transition-speed) ease,
    transform var(--transition-speed) ease;
}

.TabelSection_tabButton__2uwYs:hover {
  background-color: var(--tab-hover-bg);
  color: var(--tab-hover-font-color);
  box-shadow: 0 2px 4px rgba(0, 115, 230, 0.2);
  transform: scale(1.02);
}

.TabelSection_activeTab__14hSx {
  background-color: var(--tab-active-bg);
  color: var(--tab-active-font-color);
  box-shadow: 0 2px 6px rgba(0, 115, 230, 0.4);
  transform: scale(1.03);
}

.TabelSection_tabButton__2uwYs:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Icon Size for Tabs */
.TabelSection_tabIcon__2qtG6 {
  font-size: 16px;
}

/* Styling for the table section */
.TabelSection_tableSection__jY8ud {
  flex: 1 1; /* Takes remaining space after .treeSection if they're siblings */
  padding: 10px;
  background-color: #fafafa;
  overflow: auto; /* Internal scroll if content exceeds space */
  transition: transform var(--transition-speed) ease;
  margin-right: 20px;
}

.TabelSection_tableSection__jY8ud:hover {
  transform: none;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .TabelSection_container__2nhR_ {
    flex-direction: column;
    height: auto;
  }

  .TabelSection_treeSection__mZxaI {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
  }

  .TabelSection_tableSection__jY8ud {
    width: 100%;
    height: auto;
    margin-right: 0; /* Remove side margin for smaller screens */
  }
}

/*
src/GlobalView/styles/TreeSection.module.css
*/

:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
}
.TreeSection_treeSection__MJVKh {
    flex: 0 0 25%; /* Fixed width at 25% of the container */
    border-right: 1px solid var(--border-color);
    padding: 10px;
    background-color: #fff;
    transition: width var(--transition-speed) ease;
    display: flex;
    flex-direction: column;
    height: 100%; 
    overflow: hidden; /* Keeps anything inside from overflowing globally */
}


.TreeSection_searchContainer__2W6e7 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.TreeSection_searchInput__2JCfJ {
    flex-grow: 1;
    padding: 8px 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 14px;
    transition: border-color var(--transition-speed) ease;
}

.TreeSection_searchInput__2JCfJ:focus {
    outline: none;
    border-color: var(--primary-color);
}

.TreeSection_clearButton__3P2OK {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 8px 12px;
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease;
}

.TreeSection_clearButton__3P2OK:hover {
    background-color: var(--primary-hover-color);
}


/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .TreeSection_container__2jcdB {
        flex-direction: column;
        height: auto;
    }

    .TreeSection_treeSection__MJVKh {
        width: 100%;
        height: auto;
        border-right: none;
        border-bottom: 1px solid var(--border-color);
    }

    .TreeSection_tableSection__2nv5T {
        width: 100%;
        height: auto;
    }
}


/* Spinner.module.css */
.Spinner_spinnerOverlay__1K8kW {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999; /* Make sure it's above other content */
}

.Spinner_spinner__3iM_m {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #3498db; /* Blue color for spinner */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: Spinner_spin__2mSsb 1.5s linear infinite;
}

@keyframes Spinner_spin__2mSsb {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Login_LoginContainer__1ZQq9 {
    background-color: white;
    border-radius: 20px;
    width: 70%;
    left: 15%;
    padding: 50px;
    box-sizing: border-box;
    position: relative;
    transform: translate(0%, 50%);

    box-shadow: 3px 3px 5px rgba(207, 207, 207, 0.65);
}

.Login_LoginContainer__1ZQq9 h1 {
    font-weight: bold;
    font-size: 18px;
}

.Login_LoginForm__vxudZ > * {
    display: block;
    width: 100%;
    padding: 0;
}

.Login_LoginForm__vxudZ > label,  .Login_LoginContainer__1ZQq9 > label{
    color: gray;
    font-weight: bold;
    font-size: 14px;
    display: block;
}

.Login_LoginForm__vxudZ > input:not(.Login_LoginValidateButton__30KkX) {
    height: 35px;
    border: none;
    background-color: #E5E5E5;
    border-radius: 3px;
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 15px;
    padding-left: 5px;
    box-sizing: border-box;
}

.Login_LoginValidateButton__30KkX {
    background-color: #004aac;
    height: 56px;
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    border: none;
    margin-bottom: 10px;
}

.Login_LoginSpinnerContent__1jDoZ{
    margin-top: 10px;
    position: absolute;
    transform: translate(-50%);
    left: 50%;
}

.Login_LoginSpinnerContainer__3TJ21{
    background-color: #004aac;
    height: 56px;
    border-radius: 3px;
    text-align: center;
}


@media (min-width: 1024px) {
    .Login_LoginContainer__1ZQq9 {
        width: 40%;
        left: 30%;
        padding: 50px;
        box-sizing: border-box;
        position: relative;
        transform: translate(0%, 50%);
    }
}

