/* src/stats/AreaInStats.module.css */

/* Main container for the AreaInStats component */
.AreaInStatsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Container for each statistics item */
  .StatsContainer {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 0; /* Remove padding to allow the Graph component to control it */
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Prevent overflow */
  }
  
  /* Label styling */
  .StatsLabel {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    padding: 20px; /* Add padding to the label */
  }
  
  /* Separator under the title */
  .TitleSeparator {
    height: 2px;
    background-color: #004aac;
    width: 100%;
  }
  
  /* Graph container */
  .GraphContainer {
    position: relative;
    height: 400px; /* Adjust the height as needed */
    margin-bottom: 20px;
  }
  
  /* AlarmsList Container */
  .AlarmsListContainer {
    margin-bottom: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .StatsLabel {
      font-size: 1em;
      padding: 15px;
    }
  
    .StatsContainer {
      margin-bottom: 15px;
    }
  
    .GraphContainer {
      height: 300px; /* Adjust the height for smaller screens */
    }
  }
  