/*
src/GlobalView/Treeutils/components/styles/TreeNode.module.css
*/

/* Root variables for easy customization */
:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
}


/* Node content containing label and buttons */
.nodeContent {
    display: flex;
    align-items: center;
    width: 100%;
    
}

.nodeLabelContainer {
    display: flex;
    align-items: center;
}

/* Style adjustments for the sort button */
.sortButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 5px;
    color: #555;
    display: flex;
    align-items: center;
  }
  
  .sortButton:hover {
    color: #000;
  }
  
  /* Initially hide the sort button */
.treeNode .sortButton {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
  /* Show the sort button when the node is hovered */
  .treeNode:hover .sortButton {
    opacity: 1;
    pointer-events: auto;
  }

  /* Expanded node icon */
.expanded .arrowicon {
    transform: rotate(90deg);
}
.treeNode.expanded {
  max-height: 1000px; /* Set a large enough value to ensure smooth transition */
  opacity: 1;   
}

/* Collapsed node icon */
.collapsed .arrowicon {
    transform: rotate(0deg);
}

.treeNode.collapsed {
    opacity: 0.8;
  }

  /* Leaf node icon */
.leaf .arrowicon {
    font-size: 10px;
    transform: none;
    color: #aaa;
}

/* Icon styling */
.arrowicon {
    width: 18px;
    height: 18px;
    text-align: center;
    margin-right: 8px;
    font-size: 12px;
    transition: transform var(--transition-speed) ease;
}

.companyIcon {
    width: 18px;
    height: 18px;
    text-align: center;
    margin-right: 8px;
    font-size: 12px;
    transition: transform var(--transition-speed) ease;
    color: #1e1155;
}

.securispotIcon{
    width: 18px;
    height: 18px;
    text-align: center;
    margin-right: 8px;
    font-size: 12px;
    transition: transform var(--transition-speed) ease;
    color: #0056b3;
}


.defaultIcon{
    width: 18px;
    height: 18px;   
    text-align: left;
    margin-right: 8px;
    font-size: 12px;
    transition: transform var(--transition-speed) ease;
    color: #585b5e; 
}

/* Edit input field */
.editInput {
    width: 100%;
    padding: 4px 8px;
    font-size: 14px;
}

/* Node label */
.nodeLabel {
    flex-grow: 1;
    font-size: 14px;
    color: #333; /* Darker color for better readability */
}   


/* Styling each node in the tree */
.treeNode {
    position: relative;
    padding: 8px 10px;
    margin: 4px 0; /* Adjust margins for tighter spacing */
    border-radius: 4px;
    transition: max-height 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    opacity: 1;
    overflow: hidden;
}


.treeNode:hover {
    background-color: #eef5ff; /* Soft blue background for hover */
    /* Remove transform: translateX(3px); */
    /* Maybe add a subtle box-shadow or a slight vertical translation instead */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

/* Highlight when dropping is not allowed on Securispot */
.cannotDrop {
    background-color: rgba(255, 0, 0, 0.3); /* Red semi-transparent background */
  }

  .securispotIconRed {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    font-size: 12px;
    transition: transform 0.3s ease;
    color: red;
  }
  
  .securispotIconBlue {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    font-size: 12px;
    transition: transform 0.3s ease;
    color: blue;
  }
  