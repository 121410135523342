/* MenuItemContent.module.css */

:root {
  --v-menu-selected-background-color: #00b7ff;
  --v-menu-selected-text-color: #ffffff;
  --v-menu-unselected-text-color: #333333;
  --v-menu-hover-background: #f0f0f0;
  --v-menu-hover-text-color: #000000;
}

.MenuItemContainer {
  width: 90%;
  max-width: 90%;
  overflow: hidden;
  font-weight: bold;
  color: var(--v-menu-unselected-text-color);
  margin: 4px 0;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, margin 0.3s ease, padding 0.3s ease;
}

.MenuItemContainer:hover {
  background-color: var(--v-menu-hover-background);
  color: var(--v-menu-hover-text-color);
}

.MenuItemActive {
  background-color: var(--v-menu-selected-background-color);
  color: var(--v-menu-selected-text-color);
}

.MenuItemHeader {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  justify-content: flex-start;
  transition: padding 0.3s ease;
  width: 90%;
  max-width: 90%;
}

.MenuItemToggleIcon {
  margin-right: 12px;
  font-size: 1rem;
  cursor: pointer;
}

.MenuItemIcon {
  margin-right: 8px;
  font-size: 1.2rem;
}

.MenuItemText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: inherit;
    flex-grow: 1;
}

.Collapsed .MenuItemContainer {
  margin: 2px 0;
}

.Collapsed .MenuItemHeader {
  padding: 6px;
  justify-content: center;
}

.Collapsed .MenuItemToggleIcon {
  display: none;
}

.Collapsed .MenuItemText {
  display: none;
}

.Collapsed .MenuItemIcon {
  margin-right: 0;
  font-size: 1.4rem;
}

/* Level-specific Indentation */
.MenuItemLevel0 {
  margin-left: 0;
}

.MenuItemLevel1 {
  margin-left: 20px;
}

.MenuItemLevel2 {
  margin-left: 40px;
}

.MenuItemLevel3 {
  margin-left: 60px;
}

/* Remove indentation when collapsed */
.Collapsed .MenuItemLevel1,
.Collapsed .MenuItemLevel2,
.Collapsed .MenuItemLevel3 {
  margin-left: 0;
}
