/* src/ai/AIComponents.module.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Modal Overlay */
.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
  z-index: 99;
  backdrop-filter: blur(4px); /* Increased blur effect */
}

/* AI Modal */
.AIModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 40px 30px;
  border-radius: 15px;
  z-index: 100;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  width: 90%;
  max-height: 85vh;
  overflow-y: auto;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
}

/* Close Button */
.CloseButton {
  position: absolute;
  top: 20px;
  right: 25px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  color: #aaa;
  cursor: pointer;
  transition: color 0.2s ease;
}

.CloseButton:hover {
  color: #000;
}

/* AI Content */
.AIContent {
  text-align: left;
  max-height: 75vh;
  overflow-y: auto;
  padding-right: 5px; /* Space for custom scrollbar */
}

/* Modal Title */
.ModalTitle {
  display: flex;
  align-items: center;
  font-size: 2em;
  color: #004aac;
  margin-bottom: 25px;
  font-weight: 700;
}

.ModalTitle svg {
  margin-right: 15px;
  font-size: 1.2em;
}

/* Horizontal Line */
.AIContent hr {
  border: none;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 30px;
}

/* AI Response */
.AIResponse {
  color: #333;
  line-height: 1.8;
  font-size: 1em;
}

/* Headings */
.AIResponse h2 {
  color: #003a8a;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1.8em;
  font-weight: 700;
}

.AIResponse h3 {
  color: #004aac;
  margin-top: 25px;
  margin-bottom: 18px;
  font-size: 1.6em;
  font-weight: 600;
}

.AIResponse h4 {
  color: #005bd1;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 1.4em;
  font-weight: 500;
}

.AIResponse h5 {
  color: #006aff;
  margin-top: 15px;
  margin-bottom: 12px;
  font-size: 1.2em;
  font-weight: 500;
}

/* Paragraphs */
.AIResponse p {
  margin-bottom: 20px;
}

/* Lists */
.AIResponse ul {
  list-style-type: disc;
  margin-left: 30px;
  margin-bottom: 20px;
}

.AIResponse li {
  margin-bottom: 10px;
}

/* Interpretation Section */
.AIResponse .interpretation {
  margin-top: 30px;
  padding: 25px;
  background-color: #f0f7ff;
  border-left: 5px solid #004aac;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.AIResponse .interpretation h5 {
  margin-top: 0;
}

/* Quotes */
.AIResponse blockquote {
  margin: 20px 0;
  padding: 15px 20px;
  background-color: #f9f9f9;
  border-left: 5px solid #ddd;
  font-style: italic;
  color: #666;
}

/* Loader */
.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

/* Heatmap Container */
.HeatmapContainer {
  text-align: center;
  margin-top: 40px;
}

.HeatmapContainer img {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.HeatmapContainer img:hover {
  transform: scale(1.02);
}

/* Button Container */
.ButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  flex-wrap: wrap;
}

/* Buttons */
.ButtonContainer button {
  padding: 14px 30px;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 30px;
  cursor: pointer;
  min-width: 150px;
  font-size: 1em;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.2s ease;
  border: none;
  font-weight: 500;
}

/* Primary Button */
.PrimaryButton {
  background-color: #004aac;
  color: #fff;
}

.PrimaryButton:hover {
  background-color: #003a8a;
  box-shadow: 0 4px 15px rgba(0, 74, 172, 0.4);
}

/* Secondary Button */
.SecondaryButton {
  background-color: #f5f5f5;
  color: #333;
}

.SecondaryButton:hover {
  background-color: #e5e5e5;
}

/* Disabled Button */
.ButtonContainer button:disabled {
  background-color: #cccccc;
  color: #777;
  cursor: not-allowed;
}

/* Scrollbar Styling */
.AIContent::-webkit-scrollbar {
  width: 10px;
}

.AIContent::-webkit-scrollbar-thumb {
  background-color: #bbbbbb;
  border-radius: 5px;
}

.AIContent::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}

.AIContent::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translate(-50%, -55%); }
  to { opacity: 1; transform: translate(-50%, -50%); }
}

.AIModal {
  animation: fadeIn 0.4s ease-out;
}

/* Media Query for Small Screens */
@media (max-width: 600px) {
  .AIModal {
    width: 95%;
    padding: 30px 20px;
  }

  .ModalTitle {
    font-size: 1.6em;
  }

  .CloseButton {
    top: 15px;
    right: 15px;
  }

  .ButtonContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .ButtonContainer button {
    width: 100%;
    margin: 5px 0;
  }
}
