@value sizes: "../globalcss/sizes.module.css";
@value v-menu-width, v-devices-menu-width, v-header-height from sizes;

/* CSS Variables */
:root {
    --menu-width: v-menu-width;
    --devices-menu-width: v-devices-menu-width;
    --header-height: v-header-height;
    --container-padding: 20px;
    --button-hover-bg: #21a1f1;
    --default-padding: 10px;
    --shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Layout Adjustments */

.HomeContainer {
    padding: var(--container-padding);
    margin-top: var(--header-height);
    position: absolute;
    left: calc(var(--devices-menu-width));
    width: calc(100% - var(--devices-menu-width));
}

/* Common Utility Classes */
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Item Container */
.StatsItemContainer {
    background-color: #fff;
    padding: var(--default-padding);
    margin-bottom: var(--default-padding);
    border-radius: 8px;
    box-shadow: var(--shadow);
    transition: all 0.3s ease;
}

.StatsItemContainer > label {
    font-weight: bold;
    font-size: 16px;
    padding: var(--default-padding) 0;
    display: block;
}

/* Header Row */
.HeadRow {
    @extend .flex-space-between;
    padding-right: var(--default-padding);
}

.HeadRow > label {
    font-weight: bold;
    font-size: 16px;
}

/* Separator */
.HomeTitleSeparator {
    width: 100%;
    height: 1px;
    background-color: #D8D8D8;
    margin: 10px 0;
    border-bottom: 1px solid #ddd;
}

/* Basic Table Styles */
.Table {
    width: 100%;
}

.Table th, .Table td {
    padding: 8px;
    text-align: center;
}

.HomeTableContainer th:first-of-type,
.HomeTableContainer td:first-of-type {
    border-left: none;
}

.HomeTableContainer th:last-of-type,
.HomeTableContainer td:last-of-type {
    border-right: none;
}

.HomeTableContainer th, .HomeTableContainer td {
    font-size: 13px;
    border: 1px solid #eaeaea;
    padding: 6px;
}

/* Recent Events */
.HomeRecentEventRow > td {
    background-color: #fc525241;
}

/* Download and AI Buttons */
.ContainerDownloadButton {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
    padding: var(--default-padding);
}

/* Retry Button */
.RetryButton {
    margin: 15px 0;
    padding: 10px 15px;
    background-color: #fefefe;
    border: 1px solid lightgray;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    min-width: 120px;
}

.RetryButton:hover {
    background-color: lightgray;
}

/* Tree Menu Container */
.TreeContainer {
    position: fixed;
    width: var(--devices-menu-width);
    height: calc(100% - var(--header-height));
    left: var(--menu-width);
    background-color: white;
    padding: var(--default-padding);
    overflow-y: auto;
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.32);
    z-index: 160;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .StatsItemContainer {
        padding: 15px;
    }

    .TreeContainer {
        width: 100%;
        height: auto;
        position: relative;
    }

    .RetryButton {
        width: 100%;
        margin-top: 10px;
    }
}
