@value colors: "../../globalcss/colors.module.css";
@value v-primary from colors;
@value sizes: "../../globalcss/sizes.module.css";
@value small, medium, large from "../../globalcss/breakpoints.module.css";

:root {
  --menu-expanded-width: 240px;
  --menu-collapsed-width: 60px;
  --header-height: 53px;
}

.tabContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: var(--header-height);
  left: var(--menu-expanded-width);
  width: calc(100% - var(--menu-expanded-width));
  height: 53px;
  z-index: 110;
  background-color: white;
  transition: left 0.3s ease, width 0.3s ease;
  padding: 0 40px;
}

.tabContainerCollapsed {
  left: var(--menu-collapsed-width);
  width: calc(100% - var(--menu-collapsed-width));
}

@media (max-width: 768px) {
  .tabContainer {
    width: 100%;
    left: 0;
  }
}

.tabContent {
  display: flex;
  flex: 1;
  overflow-x: auto;
  white-space: nowrap;
}

.filterIconContainer {
  position: relative;
  display: inline-block;
}

.FilterIcon {
  display: inline-block;
  cursor: pointer;
}


.selectedTab {
  color: v-primary;
  font-weight: bold;
}

.tabSelectedLine {
  background-color: v-primary;
  height: 2px;
  margin-top: 15px;
}
