/* src/stats/AlarmsList.module.css */

/* Container for the entire AlarmsList component */
.AlarmsListContainer {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Header row containing the label and date picker */
  .HeadRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  /* Style for the table header cells */
.HeaderCell {
  position: relative; /* Necessary for the absolute positioning of the filter button */
}
  /* Label styling */
  .Label {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
  }
  
  /* Separator under the title */
  .TableHeader {
    font-weight: bold;
    background-color: #f2f2f2; /* Light gray background for the header */
    padding: 10px;
    text-align: left;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .SortIcon {
    margin-left: 5px; /* Spacing between header text and sort icon */
    color: #6c757d; /* Neutral color for the icon */
  }
  
  .TitleSeparator {
    height: 2px;
    background-color: #004aac;
    width: 100%;
    margin-bottom: 15px;
  }
  
  /* Table container with scroll */
  .TableContainer {
    overflow-y: auto;
    max-height: 400px; /* Adjust as needed */
  }
  
  /* Styling for the table */
  .Table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .Table th,
  .Table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    color: #333;
  }
  
  .Table th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  
  .Table tr:hover {
    background-color: #f9f9f9;
  }
  
  /* Styling for recent event rows */
  .RecentEventRow {
    background-color: #e6f7ff;
  }
  
  /* No Alarms Label */
  .NoAlarmsLabel {
    padding: 20px;
    text-align: center;
    color: #666;
  }
  
  /* Download Button Container */
  .DownloadButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  
  /* Download Button */
  .DownloadButton {
    padding: 8px 16px;
    border-radius: 5px;
    background: #004aac;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
  }
  
  .DownloadButton:hover {
    background-color: #003a8a;
  }
  
  .DownloadButton span {
    margin-left: 5px;
  }
  
  
  /* Loader Container */
  .LoaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust as needed */
  }
  /* Container for the filter controls */
.FilterControls {
  display: flex;
  align-items: center;
}

/* Clear Filters Button */
.ClearFiltersButton {
  padding: 8px 16px;
  border-radius: 5px;
  background: #6c757d;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}
/* Blur effect when a dropdown filter is open */
.TableBlurred {
  filter: blur(2px);
}
.ClearFiltersButton:hover {
  background-color: #5a6268;
}
  /* Responsive Design */
  @media (max-width: 768px) {
    .HeadRow {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .DownloadButtonContainer {
      justify-content: center;
    }
    .FilterControls {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .ClearFiltersButton {
      margin-bottom: 10px;
    }
  }
  