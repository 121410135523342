/* src/graph/Graph.module.css */

/* Spinner Container for Centering */
.GraphSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: fit-content;
}

/* Error Container for Displaying Errors */
.GraphErrorContainer {
  text-align: center;
  padding: 10px 15px;
  color: #d9534f;
  margin-bottom: 20px;
  font-weight: bold;
  background-color: #f9d6d5;
  border: 1px solid #d9534f;
  border-radius: 4px;
}
/* Chart Wrapper */
.ChartWrapper {
  position: relative;
  flex: 1; /* Allow it to grow and fill the available space */
  margin: 20px 0;
}

/* Button Container for Export and AI inside the card */
.ButtonContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  
}

/* Ensure the GraphContainer takes full control of the card */
.GraphContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

/* Download and AI Buttons */
.DownloadButton{
  padding: 8px 16px;
  border-radius: 5px;
  background: #004aac; /* Primary theme color */
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.DownloadButton:hover{
  background-color: #003a8a; /* Darker shade on hover */
}

/* Ensures icon and text are aligned well in buttons */
.DownloadButton span {
  margin-left: 5px;
}

/* Retry Button for Error */
.RetryButton {
  margin: 15px 0;
  padding: 10px 15px;
  background-color: #004aac;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  min-width: 120px;
}

.RetryButton:hover {
  background-color: #003a8a;
}

@media (max-width: 768px) {
  .ButtonContainer {
    justify-content: center; /* Center buttons on smaller screens */
    gap: 10px; /* Reduce spacing between buttons */
    padding: 10px;
    flex-direction: column;
  }

  .DownloadButton {
    width: 100%; /* Make buttons full width on small screens */
    margin-bottom: 10px;
  }

  .GraphContainer {
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .RetryButton {
    width: 100%;
    margin-top: 10px; /* Separate the Retry button */
  }
}
