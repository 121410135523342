  /* Root variables for easy customization */
:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
}

  
  /* ActionButtons.module.css */

.actionButtons {
    margin-left: auto;
    display: flex;
    gap: 5px;
  }

  
  .actionButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 1rem; /* Use relative units for responsiveness */
    transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .actionButton:hover {
    transform: scale(1.1);
  }
  
  .actionButton:focus {
    outline: none;
  }
  
  .actionButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Edit button styling */
  .editButton {
    color: var(--edit-button-color, #007bff);
  }
  
  .editButton:hover {
    color: var(--edit-button-hover-color, #0056b3);
  }
  
  /* Delete button styling */
  .deleteButton {
    color: var(--delete-button-color, #dc3545);
  }
  
  .deleteButton:hover {
    color: var(--delete-button-hover-color, #a71d2a);
  }
  
  /* Add button styling */
  .addButton {
    color: var(--add-button-color, #28a745);
  }
  
  .addButton:hover {
    color: var(--add-button-hover-color, #1e7e34);
  }