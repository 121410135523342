/* DropdownFilter.module.css */

/* Container for the filter button */
.FilterContainer {
  position: relative;
}

/* Filter button styling */
.FilterButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
  transition: color 0.3s;
}

.FilterButton:hover,
.FilterButton:focus {
  color: #000;
  outline: none;
}

/* Dropdown container */
.FilterDropdown {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 280px;
  animation: fadeIn 0.2s ease-in-out;
}

/* Animation for dropdown appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Close button styling */
.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s;
}

.CloseButton:hover,
.CloseButton:focus {
  color: #444;
  outline: none;
}

/* Sorting buttons container */
.SortButtons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

/* Individual sort button */
.SortButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  font-size: 16px;
  color: #555;
  transition: color 0.3s, background-color 0.3s;
}

.SortButton:hover,
.SortButton:focus {
  color: #000;
  background-color: #f0f0f0;
  border-radius: 4px;
  outline: none;
}

/* Time filter container */
.TimeFilter {
  display: flex;
  flex-direction: column;
}

/* Time input container */
.TimeInputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.TimeInputContainer label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

/* Time input field */
.TimeInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
}

.TimeInput:focus {
  border-color: #007bff;
  outline: none;
}

/* Apply button */
.ApplyButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.ApplyButton:hover,
.ApplyButton:focus {
  background-color: #0056b3;
  outline: none;
}

/* Clear button */
.ClearButton {
  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.ClearButton:hover,
.ClearButton:focus {
  background-color: #5a6268;
  outline: none;
}

/* Checkbox filter container */
.CheckboxFilter {
  display: flex;
  flex-direction: column;
}

/* Search input container */
.SearchInputContainer {
  position: relative;
  margin-bottom: 10px;
}

.SearchIcon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #aaa;
  font-size: 16px;
}

.SearchInput {
  width: 100%;
  padding: 10px 12px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
}

.SearchInput:focus {
  border-color: #007bff;
  outline: none;
}

/* Checkbox list styling */
.CheckboxList {
  max-height: 160px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.CheckboxList::-webkit-scrollbar {
  width: 8px;
}

.CheckboxList::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.CheckboxList::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

/* Individual checkbox label */
.CheckboxLabel {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.CheckboxLabel:hover {
  background-color: #f8f9fa;
}

.CheckboxLabel input {
  margin-right: 10px;
}

/* No options found message */
.NoOptions {
  padding: 15px;
  color: #777;
  text-align: center;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .FilterDropdown {
    width: 90vw;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Adjustments for different dropdown positions */
.bottom-right {
  top: 100%;
  left: 0;
  margin-top: 10px;
}

.bottom-left {
  top: 100%;
  right: 0;
  margin-top: 10px;
}

.top-right {
  bottom: 100%;
  left: 0;
  margin-bottom: 10px;
}

.top-left {
  bottom: 100%;
  right: 0;
  margin-bottom: 10px;
}
