/* HeaderNode.module.css */

:root {
    --v-menu-color: #ffffff;
    --v-menu-item-text-color: #333333;
    --v-header-height: 60px;
  }
  
  .HeaderContainer {
    width: 100%;
    position: relative;
    background-color: var(--v-menu-color);
  }
  
  .MenuHeader {
    height: var(--v-header-height);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .HeaderLogo {
    height: calc(var(--v-header-height) * 0.7);
    margin-top: calc(var(--v-header-height) * 0.15);
    margin-bottom: calc(var(--v-header-height) * 0.15);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .CollapseButton {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .CollapseButtonCollapsed {
    position: static;
  }
  
  .CollapseButton:not(.CollapseButtonCollapsed) {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .CollapseIcon {
    font-size: 1.2rem;
    color: var(--v-menu-item-text-color);
    transition: transform 0.3s ease;
  }
  
  .RotateIcon {
    transform: rotate(180deg);
  }
  
  .CollapseButton:hover .CollapseIcon {
    color: #007bff; /* Change color on hover */
  }
  