/* src/App.module.css */

@value sizes: "./globalcss/sizes.module.css";
@value v-header-height from sizes;

:root {
  --menu-expanded-width: 240px; /* Adjust as needed */
  --menu-collapsed-width: 60px;
  --header-height: v-header-height; /* Use the imported header height */
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.AppContent {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.MainContent {
  flex: 1;
  position: relative;
  overflow-y: auto;
  transition: margin-left 0.3s ease;
  margin-top: var(--header-height);
  height: calc(100vh - var(--header-height));
}

.MainContentExpanded {
  margin-left: var(--menu-expanded-width);
}

.MainContentCollapsed {
  margin-left: var(--menu-collapsed-width);
}
