/* Menu.module.css */
@value colors: "../../globalcss/colors.module.css";
@value v-menu-color, v-menu-hover-background, v-menu-selected-background-color, v-menu-selected-text-color, v-menu-unselected-text-color from colors;

@value sizes: "../../globalcss/sizes.module.css";
@value v-header-height, v-menu-width from sizes;

@value small, medium, large from "../../globalcss/breakpoints.module.css";

.MenuContainer {
  position: fixed;
  z-index: 200;
  width: var(--menu-expanded-width);  
  height: 100%;
  background-color: var(--v-menu-color);
  left: 0;
  overflow: hidden; /* Prevent scrollbars during transition */
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease, box-shadow 0.3s ease;
  flex-shrink: 0;

}

.MenuContainerCollapsed {
  width: var(--menu-collapsed-width);
  overflow: hidden;
}

.CollapseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.CollapseIcon {
  font-size: 1.2rem;
  color: var(--v-menu-item-text-color);
  transition: transform 0.3s ease;
}

.RotateIcon {
  transform: rotate(180deg); /* Rotate icon when menu is collapsed */
}

.MenuHeader {
  position: relative;
  height: v-header-height;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeaderLogo {
  height: calc(v-header-height * 0.7);
  margin-top: calc(v-header-height * 0.15);
  margin-bottom: calc(v-header-height * 0.15);
  align-self: center;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.HeaderLogoCollapsed {
  opacity: 0;
  transform: scale(0.8); /* Scale down when collapsed */
}

.MenuHeaderSeparator {
  width: 100%;
  height: 1px;
  background-color: rgb(217, 217, 217);
  margin: 0 auto 10px auto;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.MenuHeaderSeparatorCollapsed {
  opacity: 0; /* Hide separator when collapsed */
}

.TreeContainer {
  flex: 1;
  overflow-y: auto; /* Vertical scroll only */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%;
  height: 100%;
}

.MenuAppVersion {
  padding: 15px 0;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.MenuAppVersionCollapsed {
  opacity: 0;
  pointer-events: none; /* Hide text and disable interactions when collapsed */
}

@media small {
  .MenuContainer {
    display: none; /* Hide menu on small screens */
  }
}
