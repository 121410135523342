/* AIButton.module.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* AI Analysis Button */
.AIButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  font-family: 'Roboto', Arial, sans-serif;
  position: relative;
}

.ButtonContent {
  padding: 12px 24px;
  border-radius: 30px;
  background: linear-gradient(45deg, #004aac, #007bff);
  transition: background 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 1em;
  position: relative;
  overflow: hidden; /* For the ripple effect */
}

.ButtonContent:hover {
  background: linear-gradient(45deg, #003a8a, #006aff);
  box-shadow: 0 8px 20px rgba(0, 74, 172, 0.6);
}

/* Ripple Effect */
.ButtonContent::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 0;
  transition: width 0.6s ease, height 0.6s ease, opacity 1s;
}

.ButtonContent:active::after {
  width: 250%;
  height: 250%;
  opacity: 0;
  transition: 0s;
}

/* Icon Wrapper */
.IconWrapper {
  position: relative;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

/* Icon Styling */
.Icon {
  font-size: 1.5em;
  transition: transform 0.3s ease, color 0.3s ease;
  color: #fff;
}

/* Bobbing Animation */
@keyframes bob {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.ButtonContent:hover .Icon {
  animation: bob 1s ease-in-out infinite;
}

/* Speech Bubble Styling */
.SpeechBubble {
  position: absolute;
  top: -60px;
  left: 25px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 10px 15px;
  opacity: 0;
  transform: translateY(10px) scale(0.9);
  transform-origin: bottom left;
  transition: opacity 0.4s ease, transform 0.4s ease;
  pointer-events: none;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.SpeechBubble::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 25px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.ButtonContent:hover .SpeechBubble {
  opacity: 1;
  transform: translateY(0) scale(1);
}

/* Dot Styling */
.Dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #004aac;
  border-radius: 50%;
  opacity: 0;
  animation: blink 1.5s infinite both, colorChange 3s infinite;
}

.Dot:nth-child(1) {
  animation-delay: 0s;
}

.Dot:nth-child(2) {
  animation-delay: 0.3s;
}

.Dot:nth-child(3) {
  animation-delay: 0.6s;
}

/* Dot Blink Animation */
@keyframes blink {
  0% { opacity: 0.2; transform: scale(0.8); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { opacity: 0.2; transform: scale(0.8); }
}

/* Dot Color Change */
@keyframes colorChange {
  0% { background-color: #004aac; }
  50% { background-color: #007bff; }
  100% { background-color: #004aac; }
}

/* Button Text */
.ButtonText {
  position: relative;
  z-index: 1;
}

/* Loader Text */
.ModalContent p {
  margin-top: 20px;
  font-size: 1.2em;
  color: #004aac;
  animation: pulse 2s infinite;
}

/* Pulse Animation */
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
}

/* Modal Overlay */
.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  backdrop-filter: blur(4px);
}

/* Modal Content for the loader */
.ModalContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  z-index: 100;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
  max-width: 450px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', Arial, sans-serif;
}

/* Media Query for Small Screens */
@media (max-width: 600px) {
  .ButtonContent {
    padding: 10px 18px;
    font-size: 0.9em;
  }

  .SpeechBubble {
    top: -70px;
    left: 15px;
  }

  .SpeechBubble::after {
    left: 20px;
  }
}
