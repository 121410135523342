/* src/navigation/tabs/filter/FilterModal.module.css */

@value colors: "../../../globalcss/colors.module.css";
@value v-primary, v-secondary, v-border-color, v-light-gray from colors;

:root {
  /* Assign imported constants to CSS variables */
  --primary-color: v-primary;
  --secondary-color: v-secondary;
  --border-color: v-border-color;
  --light-gray: v-light-gray;

  /* Modal variables */
  --modal-width: 340px;
  --modal-padding: 24px;
  --modal-bg-color: #ffffff;
  --modal-border-radius: 12px;
  --modal-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  --close-button-size: 26px;
  --close-button-color: #aaa;
  --hover-bg-color: #f1f1f1;
  --apply-button-hover: #003d73;
  --modal-title-font-size: 1.3rem;
}

/* Main Filter Modal Container */
.FilterWindow {
  position: absolute;
  top: 100%; /* Positions the dropdown directly below the icon */
  left: 0;
  width: var(--modal-width);
  background-color: var(--modal-bg-color);
  border-radius: var(--modal-border-radius);
  padding: var(--modal-padding);
  box-shadow: var(--modal-shadow);
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}

/* Close Button */
.ModalClose {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: var(--close-button-size);
  color: var(--close-button-color);
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.ModalClose:hover {
  color: var(--primary-color);
}

/* Modal Title */
.ModalTitle {
  font-size: var(--modal-title-font-size);
  color: var(--primary-color);
  margin-bottom: 16px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 12px;
}

/* Modal Content */
.ModalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 400px;
  overflow-y: auto;
  padding-top: 8px;
}

/* Checkbox Option Container */
.ModalOption {
  display: flex;
  align-items: center;
  padding: 8px 0;
  transition: background-color 0.2s ease-in-out;
  border-radius: 6px;
}

.ModalOption:hover {
  background-color: var(--hover-bg-color);
}

.ModalOption input[type="checkbox"] {
  margin-right: 12px;
  cursor: pointer;
  transform: scale(1.1);
}

.ModalTitleOption {
  color: var(--secondary-color);
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.2s ease-in-out;
}

.ModalTitleOption:hover {
  color: var(--primary-color);
}

/* Select All Checkbox */
.ModalOption #selectAll {
  font-weight: bold;
}

/* Apply Button */
.ModalApply {
  margin-top: 20px;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease;
  width: 100%;
}

.ModalApply:hover {
  background-color: var(--apply-button-hover);
  transform: scale(1.02);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .FilterWindow {
    width: 90vw;
    left: calc(50% - 45vw);
  }

  .ModalTitle {
    font-size: 1.1rem;
  }

  .ModalOption {
    padding: 6px 0;
  }
}
