@value colors: "../../../globalcss/colors.module.css";
@value v-primary, v-secondary, v-border-color from colors;
@value sizes: "../../../globalcss/sizes.module.css";
@value small, medium, large from "../../../globalcss/breakpoints.module.css";

/* Container for DateRangeSelector with responsive adjustments */
.DateRangeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin: 10px 0;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--v-border-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.DateRangeContainer:hover {
  background-color: var(--v-secondary);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Styling the DateRangePicker input field */
.DateRangePicker {
  width: 100%;
  max-width: 320px;
  border-radius: 4px;
  border: 1px solid var(--v-border-color);
  padding: 8px 12px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.DateRangePicker:focus,
.DateRangePicker:hover {
  border-color: var(--v-primary);
  background-color: #fff;
}

.DateRangePicker::placeholder {
  color: #aaa;
  font-style: italic;
}

/* DateRangePicker's Dropdown Calendar Styling */
.DateRangeDropdown {
  border: 1px solid var(--v-border-color);
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  width: 100%;
  max-width: 320px;
}

.DateRangeDropdown .rs-picker-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  font-size: 16px;
  color: #555;
  background-color: white;
  cursor: pointer;
}

.DateRangeDropdown .rs-picker-toggle:hover {
  background-color: var(--v-secondary);
}

/* Date Range Options and Values */
.RangeOption {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.RangeOption:hover {
  background-color: var(--v-primary);
  color: white;
}

/* Responsiveness for Smaller Screens */
@media (max-width: 768px) {
  .DateRangeContainer {
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
  }

  .DateRangePicker {
    width: 100%;
  }

  .DateRangeDropdown {
    width: 100%;
  }
}

/* Specific Styles for Very Small Screens */
@media (max-width: 480px) {
  .DateRangeContainer {
    padding: 6px;
  }

  .DateRangePicker {
    font-size: 14px;
    padding: 6px 10px;
  }

  .RangeOption {
    font-size: 13px;
  }
}
