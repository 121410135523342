/*
src/GlobalView/styles/Tree.module.css
*/

:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
}

.treeContainer {
    flex-grow: 1;
    padding: 10px;
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow var(--transition-speed) ease, background-color var(--transition-speed) ease;
    overflow-y: auto; /* Allow vertical scrolling when needed */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.treeContainer:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Add subtle hover effect */
}
.treeContainer::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 4px;
}

.treeComponent {
    height: 100%; /* Ensure it takes full height */
    width: 100%; /* Full width of parent container */
    max-height: 100vh; /* Constrain height to viewport */
    padding: 10px 0;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

