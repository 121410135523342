/*
src/GlobalView/styles/TreeSection.module.css
*/

:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
}
.treeSection {
    flex: 0 0 25%; /* Fixed width at 25% of the container */
    border-right: 1px solid var(--border-color);
    padding: 10px;
    background-color: #fff;
    transition: width var(--transition-speed) ease;
    display: flex;
    flex-direction: column;
    height: 100%; 
    overflow: hidden; /* Keeps anything inside from overflowing globally */
}


.searchContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.searchInput {
    flex-grow: 1;
    padding: 8px 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 14px;
    transition: border-color var(--transition-speed) ease;
}

.searchInput:focus {
    outline: none;
    border-color: var(--primary-color);
}

.clearButton {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 8px 12px;
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease;
}

.clearButton:hover {
    background-color: var(--primary-hover-color);
}


/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        height: auto;
    }

    .treeSection {
        width: 100%;
        height: auto;
        border-right: none;
        border-bottom: 1px solid var(--border-color);
    }

    .tableSection {
        width: 100%;
        height: auto;
    }
}

