/* src/cards/LastEventDateCard.module.css */

/* Container for the LastEventDateCard component */
.LastEventDateCardContainer {
    width: 350px;
    margin-right: 10px;
    margin-bottom: 15px;
    display: inline-block;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .LastEventDateCardContainer {
      width: 100%;
      margin-right: 0;
      display: block;
    }
  }
  