/* src/navigation/header/Header.module.css */

@value colors: "../../globalcss/colors.module.css";
@value v-header-background-color, v-menu-selected-background-color from colors;

@value sizes: "../../globalcss/sizes.module.css";
@value v-header-height from sizes;

.HeaderContainer {
  width: 100%;
  height: v-header-height;
  background-color: v-header-background-color;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 180;
  border-bottom: 1px solid rgb(217, 217, 217);
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjusted to space between logo and profile */
  padding: 0 20px; /* Padding on both sides */
}

.HeaderLogo {
  height: calc(v-header-height * 0.6); /* Adjust logo size */
  cursor: pointer;
}

.HeaderProfileContainer {
  position: relative; /* Changed from fixed to relative */
  display: flex;
  align-items: center;
  cursor: pointer;
}

.HeaderProfileContent {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.HeaderProfileContent:hover {
  background-color: v-menu-selected-background-color;
}

.HeaderProfileContent label {
  margin: 0 8px;
  font-size: 14px;
}

.ProfileIcon {
  font-size: 1.2rem;
}

.ChevronIcon {
  font-size: 0.8rem;
}

.HeaderLogoutContainer {
  position: absolute;
  top: calc(v-header-height);
  right: 0;
  background-color: #ffffff;
  border: 1px solid #bebebe;
  width: 160px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 190;
}

.HeaderLogoutContainer::before {
  content: "";
  position: absolute;
  top: -8px;
  right: 16px;
  border-width: 0 8px 8px 8px;
  border-style: solid;
  border-color: transparent transparent #bebebe transparent;
}

.HeaderLogoutContent {
  padding: 10px;
  text-align: center;
}

.HeaderLogoutContent label {
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.HeaderLogoutContent label:hover {
  color: #000;
}

@media (max-width: 768px) {
  .HeaderContainer {
    padding: 0 10px;
  }

  .HeaderProfileContent label {
    display: none; /* Hide username on smaller screens */
  }
}
