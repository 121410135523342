:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
}



/* AssociateButton.module.css */

.associateButtonContainer {
    margin-left: auto;
  }

  .associateButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 16px;
    color: red; /* Bootstrap info color */
    transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .associateButton:hover {
    transform: scale(1.1);
    color: darkred; /* Darker info color */
  }
  
  .associateButton.associated {
    color: #17a2b8; /* Change to Bootstrap info color for associated */
  }
  
  .associateButton.associated:hover {
    color: #117a8b; /* Darker info color */
  }
  
  .associateButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
