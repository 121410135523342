/*
src/GlobalView/styles/TabelSection.module.css
*/

:root {
  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --background-color: #f0f0f0;
  --tree-node-bg: #f8f8f8;
  --tree-node-hover-bg: #e6f7ff;
  --table-header-bg: #f1f1f1;
  --table-row-hover-bg: #f7f7f7;
  --border-color: #ddd;
  --border-radius: 8px;
  --transition-speed: 0.3s;

  /* Extra variables for a polished tab section */
  --tab-bg: #f4f4f4;
  --tab-hover-bg: #e0f3ff;
  --tab-active-bg: #0073e6;
  --tab-font-color: #555;
  --tab-hover-font-color: #0073e6;
  --tab-active-font-color: #fff;
  --box-shadow-light: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Table Content Section */
.tableContent {
  margin-top: 10px;
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-light);
  padding: 1rem; /* Optional: Add some internal padding for breathing room */
}

/* Tabs Container */
.tabsContainer {
  display: flex;
  flex-direction: row; /* Aligns tabs horizontally (not vertically) */
  gap: 10px;          /* Adds spacing between the tabs */
  margin-right: 16px; /* Space between tabs and table content */
  padding: 8px 0;     /* Vertical padding around the tabs */
  background-color: var(--background-color);
  border-right: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-light);
  overflow-x: auto;   /* Allow horizontal scroll if tabs exceed container width */
}

/* Tab Buttons */
.tabButton {
  background-color: var(--tab-bg);
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  color: var(--tab-font-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color var(--transition-speed) ease,
    color var(--transition-speed) ease,
    transform var(--transition-speed) ease;
}

.tabButton:hover {
  background-color: var(--tab-hover-bg);
  color: var(--tab-hover-font-color);
  box-shadow: 0 2px 4px rgba(0, 115, 230, 0.2);
  transform: scale(1.02);
}

.activeTab {
  background-color: var(--tab-active-bg);
  color: var(--tab-active-font-color);
  box-shadow: 0 2px 6px rgba(0, 115, 230, 0.4);
  transform: scale(1.03);
}

.tabButton:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Icon Size for Tabs */
.tabIcon {
  font-size: 16px;
}

/* Styling for the table section */
.tableSection {
  flex: 1; /* Takes remaining space after .treeSection if they're siblings */
  padding: 10px;
  background-color: #fafafa;
  overflow: auto; /* Internal scroll if content exceeds space */
  transition: transform var(--transition-speed) ease;
  margin-right: 20px;
}

.tableSection:hover {
  transform: none;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
  }

  .treeSection {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
  }

  .tableSection {
    width: 100%;
    height: auto;
    margin-right: 0; /* Remove side margin for smaller screens */
  }
}
