@value colors: "../../../globalcss/colors.module.css";
@value v-primary, v-secondary, v-border-color from colors;
@value sizes: "../../../globalcss/sizes.module.css";
@value small, medium, large from "../../../globalcss/breakpoints.module.css";

/* Root Variables for Dynamic Widths */
:root {
  --tab-padding: 12px 20px;
  --tab-font-size: 16px;
  --tab-icon-size: 1.1rem;
}

/* Container for Each Tab Item */
.tabItem {
  display: inline-flex;
  align-items: center;
  padding: var(--tab-padding);
  font-size: var(--tab-font-size);
  color: #555;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
}
/* Add this to your CSS */
.tabItem label {
  cursor: pointer;
}

/* Selected Tab Styling */
.selectedTab {
  color: var(--v-primary);
  font-weight: bold;
  border-bottom-color: var(--v-primary);
}

/* Default (Unselected) Tab Styling */
.tab {
  color: var(--v-secondary);
}

/* Indicator Line for Selected Tab */
.tabSelectedLine {
  width: 100%;
  height: 2px;
  background-color: var(--v-primary);
  transition: transform 0.3s ease;
  cursor: pointer;
}

/* Responsive Icon Styling */
.TabIcon {
  font-size: var(--tab-icon-size);
  margin-right: 8px;
  cursor: pointer;
}

/* Hover State */
.tabItem:hover {
  color: var(--v-primary);
  cursor: pointer;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .tabItem {
    padding: 10px 16px;
    font-size: 14px;
  }

  .TabIcon {
    font-size: 1rem;
    margin-right: 6px;
  }
}

@media (max-width: 480px) {
  .tabItem {
    padding: 8px 12px;
    font-size: 13px;
  }

  .TabIcon {
    font-size: 0.9rem;
  }
}
