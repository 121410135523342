/*src/card/Card.module.css*/

.Card {
    padding: 10px;
    border-radius: 10px;

    height: 100%;
}

.Card > label:first-of-type{
    color: white;
    font-size: 20px;
    font-weight: bold;
    display: block;
}

.Card > label:nth-child(2n){
    color: white;
    width: 100%;
    text-align: center;
    font-size: 25px;
    margin-top: 10px;
}

.CardDetailsButton {
    background: none;
    border: none;
    position: absolute;
    bottom: 5px;
    right: 15px;
    color: white;
    padding: 5px;
}

.Card > label > a:hover {
    color: white;
    text-decoration: none;
}
