/* FooterNode.module.css */

.FooterContainer {
    width: 100%;
    text-align: center;
    padding: 15px 0;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease;
  }
  
  .MenuAppVersion {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .MenuAppVersionCollapsed {
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
  }
  
  .MenuCopyright {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.5);
  }
  