/*
src/GlobalView/styles/GlobaleView.module.css
*/

/* Root variables for easy customization */
:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;

    /* Additional variables for cohesive design */
    --box-shadow-base: 0 2px 10px rgba(0, 0, 0, 0.05);
    --highlight-color: yellow;
    --text-color-primary: #333;
    --text-color-secondary: #555;
    --input-bg: #fff;
}

/* General container styling for the global view */
.container {
    display: flex;
    height: 100vh;                 /* fill the entire viewport height */
    width: 100%;
    overflow: hidden;              /* hide scroll on the global container */
    background-color: var(--background-color);
    transition: background-color var(--transition-speed) ease-in-out;
}

/* Collapsible container for optional toggled content */
.collapsibleContainer {
    overflow: hidden;
    max-height: 0;                 /* collapsed by default */
    opacity: 0;                    /* invisible by default */
    transition: max-height var(--transition-speed) ease,
                opacity var(--transition-speed) ease;
}

/* Node content container to neatly space child elements */
.nodeContentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        height: auto;
    }
}

/* Basic table styling */
table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;         /* Or var(--background-color) if preferred */
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: var(--box-shadow-base);
}

caption {
    caption-side: top;
    font-size: 1.5em;
    margin-bottom: 10px;
    color: var(--primary-color);
    font-weight: bold;
}

/* Table header styling */
th {
    background-color: var(--table-header-bg);
    font-weight: bold;
    padding: 12px;
    text-align: left;
    border-bottom: 2px solid var(--border-color);
    position: sticky;
    top: 0;
    z-index: 1;
    color: var(--text-color-primary);
}

/* Table data cell styling */
td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
    transition: background-color var(--transition-speed) ease;
    color: var(--text-color-secondary);
}

/* Hover effect for rows */
tr:hover td {
    background-color: var(--table-row-hover-bg);
}

/* Zebra striping for even rows */
tr:nth-child(even) td {
    background-color: var(--tree-node-bg);
}

/* Responsive table adjustments for smaller screens */
@media (max-width: 768px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    th {
        /* Hide table headers (they’ll be replaced by data-label) */
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    td {
        position: relative;
        padding-left: 50%;
        text-align: left;
    }

    td::before {
        position: absolute;
        top: 12px;
        left: 10px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
        color: #666;
        content: attr(data-label);
    }

    tr {
        margin-bottom: 15px;
        border-bottom: 2px solid var(--border-color);
    }
}

/* Highlight class for special emphasis */
.highlight {
    background-color: var(--highlight-color);
    font-weight: bold;
}

/* Input field styling */
input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    margin-bottom: 10px;
    background-color: var(--input-bg);
}

/* Button styling */
.button {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease, 
                transform 0.1s ease;
}

.button:hover {
    background-color: var(--primary-hover-color);
}

.button:active {
    transform: scale(0.95);
}

/* Scrollbar styling */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--primary-hover-color);
}

/* Optionally hide columns */
.hiddenColumn {
    display: none;
}
