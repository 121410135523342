:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #f0f0f0;
    --tree-node-bg: #f8f8f8;
    --tree-node-hover-bg: #e6f7ff;
    --table-header-bg: #f1f1f1;
    --table-row-hover-bg: #f7f7f7;
    --border-color: #ddd;
    --border-radius: 8px;
    --transition-speed: 0.3s;
  
    /* Additional optional variables if you like */
    --info-color: #17a2b8;
    --info-color-dark: #117a8b;
    --danger-color: red;
    --danger-color-dark: darkred;
  }
  
  /* Container for the associate button, aligned to the right */
  .associateButtonContainer {
    margin-left: auto; /* pushes other content left */
  }
  
  /* Main styling for the associate button */
  .associateButton {
    background: none;
    border: none;
    padding: 0;
    font-size: 16px;
    color: var(--danger-color); /* Default color when not associated */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* Smooth transitions */
    transition: color var(--transition-speed) ease,
                transform var(--transition-speed) ease;
  }
  
  /* Hover effect for the button */
  .associateButton:hover {
    transform: scale(1.1);
    color: var(--danger-color-dark); /* Darker color on hover */
  }
  
  /* If the item is associated, override the color */
  .associateButton.associated {
    color: var(--info-color); /* Your Bootstrap info color */
  }
  
  /* Hover state for associated button */
  .associateButton.associated:hover {
    color: var(--info-color-dark); /* Darker info color */
  }
  
  /* Disabled state for the button */
  .associateButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  